import { createContext, useContext, useEffect, useState } from 'react';
const AuthContext = createContext(null);
export const useAuthContext = () => useContext(AuthContext);
export const AuthProvider = ({ children }) => {
    const initData = JSON.parse(localStorage.getItem('admin')) || {
        isAuthenticated: false,
        token: null,
        tokenLife: null,
        expire: null,
        refreshToken: null,
    };
    const [admin, setAdmin] = useState(initData);
    useEffect(() => {
        localStorage.setItem('admin', JSON.stringify(admin));
        const a = JSON.parse(localStorage.getItem('admin'));
    }, [admin]);

    return (
        <AuthContext.Provider value={{ admin, setAdmin }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
