import {
  TableCell,
  TestNetCell,
  TableImageCell,
  Action,
} from "examples/Tables/components/CellComponents";

export const generateRows = (rows, handleEdit, handleDelete) => {
  return rows.map((row) => ({
    ID: <TableCell text={row.id} ml={3} />,
    Link: <TableCell text={row.link} />,
    "Logo Url": <TableImageCell url={row.logo_url} height={30} />,
    Name: <TableCell text={row.name} />,
    Type: <TableCell text={row.type} />,
    "Active Status": <TestNetCell flag={row.active_status} />,
    Action: <Action item={row} onEdit={handleEdit} onDelete={handleDelete} />,
  }));
};

export const columns = [
  { name: "ID", align: "left" },
  { name: "Link", align: "left" },
  { name: "Logo Url", align: "left" },
  { name: "Name", align: "left" },
  { name: "Type", align: "left" },
  { name: "Active Status", align: "center" },
  { name: "Action", align: "center" },
];
