import { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";

// @mui material components
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { EditOutlined } from "@mui/icons-material";
import { Chip, Divider, IconButton, InputLabel, Select, MenuItem, Dialog } from "@mui/material";
import MUIDataTable from "mui-datatables";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import defaultImage from "assets/images/default/default.jpg";
import { getPackages, savePackage, addPackage, deletePackage } from "service/apiCaller";
import SnackbarUtils from "utils/SnackbarUtils";

function SubscriptionPlan() {
  const [rows, setRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModal_add, setShowModal_add] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [name, setName] = useState("");
  const [period, setPeriod] = useState("");
  const [percent, setPercent] = useState("");
  const [withdrawFee, setWithdrawFee] = useState("");
  const [status, setStatus] = useState("");
  const [logo, setLogo] = useState("");
  const [previewLogo, setPreviewLogo] = useState(defaultImage);
  const logoRef = useRef(null);

  const handleLogoChange = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      setLogo(selectedImage);
      setPreviewLogo(URL.createObjectURL(selectedImage));
    }
  };

  const handleLogoUpload = () => {
    logoRef.current.click();
  };

  const getMutataion = useMutation(getPackages, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        setRows(response.data.data);
      }
    },
  });

  useEffect(() => {
    getMutataion.mutate();
  }, []);

  const saveMutation = useMutation(savePackage, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        SnackbarUtils.success(response.data.message);
        setShowModal(false);
        getMutataion.mutate();
      }
    },
  });

  const handleSavePackage = () => {
    const payload = new FormData();
    payload.append("id", selectedId);
    payload.append("name", name);
    payload.append("package", logo);
    payload.append("period", period);
    payload.append("percent", percent);
    payload.append("withdrawal_fee", withdrawFee);
    payload.append("status", status);

    saveMutation.mutate(payload);
  };

  const addPackageMutation = useMutation(addPackage, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        SnackbarUtils.success(response.data.message);
        setShowModal_add(false);
        getMutataion.mutate();
      }
    },
  });

  const handleAddPackage = () => {
    const payload = new FormData();
    payload.append("name", name);
    payload.append("package", logo);
    payload.append("period", period);
    payload.append("percent", percent);
    payload.append("withdrawal_fee", withdrawFee);
    payload.append("status", status);
    addPackageMutation.mutate(payload);
  };

  const deleteMutation = useMutation(deletePackage, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        SnackbarUtils.success(response?.data?.message);
        getMutataion.mutate();
      }
    },
  });
  const handleDeletePackage = () => {
    const payload = {
      id: selectedId,
    };
    deleteMutation.mutate(payload);
    setShowModal(false);
  };

  const handleModalOpen = (dataIndex) => {
    const { id, name, logo, period, percent, withdrawal_fee, status } = rows[dataIndex];
    setShowModal(true);
    setSelectedId(id);
    setName(name);
    setPreviewLogo(logo ? logo : defaultImage);
    setPeriod(period);
    setPercent(percent);
    setWithdrawFee(withdrawal_fee);
    setStatus(status);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalClose_add = () => {
    setShowModal_add(false);
  }

  const columns = [
    {
      name: "name",
      label: "Package",
      options: {
        setCellHeaderProps: () => {
          return { align: "left" };
        },
        setCellProps: () => {
          return { align: "left" };
        },
      },
    },
    {
      name: "logo",
      label: "Logo",
      options: {
        setCellHeaderProps: () => {
          return { align: "left" };
        },
        setCellProps: () => {
          return { align: "left" };
        },
        customBodyRender: (value) => {
          return <ArgonBox component="img" src={value ? value : defaultImage} width="50px" />;
        },
      },
    },
    {
      name: "period",
      label: "Period",
      options: {
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => {
          return { align: "right" };
        },
      },
    },
    {
      name: "percent",
      label: "Percentage",
      options: {
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => {
          return { align: "right" };
        },
      },
    },
    {
      name: "withdrawal_fee",
      label: "Withdraw Fee",
      options: {
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => {
          return { align: "right" };
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        setCellHeaderProps: () => {
          return { align: "center" };
        },
        setCellProps: () => {
          return { align: "center" };
        },
        customBodyRender: (value) => {
          if (value) {
            return <Chip color="primary" label="Active" />;
          } else {
            return <Chip color="warning" label="InActive" />;
          }
        },
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        setCellHeaderProps: () => {
          return { align: "center" };
        },
        setCellProps: () => {
          return { align: "center" };
        },
        customBodyRenderLite: (dataIndex) => {
          return (
            <IconButton color="primary" onClick={() => handleModalOpen(dataIndex)}>
              <EditOutlined />
            </IconButton>
          );
        },
      },
    },
  ];

  const option = {
    responsive: "simple",
    selectableRows: "none",
    sort: false,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    pagination: false,
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            root: {
              boxShadow: "none !important",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              "&:nth-of-type(7)": {
                width: 100,
              },
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              padding: "5px 12px !important",
            },
          },
        },
        MUIDataTableFooter: {
          styleOverrides: {
            root: {
              "& > tfoot > tr > td": {
                borderBottom: 0,
                padding: "0 16px !important",
              },
            },
          },
        },
        MuiChip: {
          styleOverrides: {
            root: {
              marginLeft: "8px !important",
            },
          },
        },
      },
    });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonButton color="primary" onClick={() => setShowModal_add(true)}>
        Add Package
      </ArgonButton>
      <ArgonBox>
        <Card>
          <ArgonBox px={4} py={5}>
            <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable data={rows} columns={columns} options={option} />
            </ThemeProvider>
          </ArgonBox>
        </Card>
      </ArgonBox>

      <Dialog
        open={showModal}
        sx={{ "& .MuiPaper-root": { width: 600 } }}
        onClose={handleModalClose}
      >
        <ArgonBox p={4}>
          <ArgonTypography>Edit Package</ArgonTypography>
          <Divider />
          <ArgonBox>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={3}>
                <ArgonBox>
                  <InputLabel sx={{ fontSize: "14px", mb: 0.25 }}>Logo</InputLabel>
                  <ArgonBox
                    component="img"
                    src={previewLogo}
                    width="100%"
                    borderRadius="8px"
                    onClick={handleLogoUpload}
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleLogoChange}
                    ref={logoRef}
                    hidden
                  />
                </ArgonBox>
              </Grid>
              <Grid item xs={12} lg={9}>
                <ArgonBox>
                  <InputLabel sx={{ fontSize: "14px", mb: 0.25 }}>Package Name</InputLabel>
                  <ArgonInput value={name} onChange={(e) => setName(e.target.value)} />
                </ArgonBox>
                <ArgonBox mt={1}>
                  <InputLabel sx={{ fontSize: "14px", mb: 0.25 }}>Period</InputLabel>
                  <ArgonInput
                    type="number"
                    value={period}
                    onChange={(e) => setPeriod(e.target.value)}
                  />
                </ArgonBox>
                <ArgonBox mt={1}>
                  <InputLabel sx={{ fontSize: "14px", mb: 0.25 }}>Percentage</InputLabel>
                  <ArgonInput
                    type="number"
                    value={percent}
                    onChange={(e) => setPercent(e.target.value)}
                  />
                </ArgonBox>
              </Grid>
            </Grid>
            <ArgonBox mt={1}>
              <InputLabel sx={{ fontSize: "14px", mb: 0.25 }}>Withdraw Fee</InputLabel>
              <ArgonInput
                type="number"
                value={withdrawFee}
                onChange={(e) => setWithdrawFee(e.target.value)}
              />
            </ArgonBox>
            <ArgonBox mt={1}>
              <InputLabel sx={{ fontSize: "14px", mb: 0.25 }}>Status</InputLabel>
              <Select
                color="primary"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                sx={{
                  "& .MuiSelect-select": {
                    width: "100% !important",
                  },
                  "& .MuiSvgIcon-root": { display: "block" },
                  height: "40px !important",
                  paddingLeft: "0 !important",
                }}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>InActive</MenuItem>
              </Select>
            </ArgonBox>
            <ArgonBox mt={2} textAlign="right">
              <ArgonButton color="primary" onClick={handleSavePackage}>
                Save
              </ArgonButton>
              <ArgonButton color="danger" onClick={handleDeletePackage}>
                Delete
              </ArgonButton>              
              <ArgonButton color="dark" onClick={handleModalClose} sx={{ ml: 1 }}>
                Cancel
              </ArgonButton>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
        {/* </Card> */}
      </Dialog>
      <Dialog
        open={showModal_add}
        sx={{ "& .MuiPaper-root": { width: 600 } }}
        onClose={handleModalClose_add}
      >
        <ArgonBox p={4}>
          <ArgonTypography>Add Package</ArgonTypography>
          <Divider />
          <ArgonBox>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={3}>
                <ArgonBox>
                  <InputLabel sx={{ fontSize: "14px", mb: 0.25 }}>Logo</InputLabel>
                  <ArgonBox
                    component="img"
                    src={previewLogo}
                    width="100%"
                    borderRadius="8px"
                    onClick={handleLogoUpload}
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleLogoChange}
                    ref={logoRef}
                    hidden
                  />
                </ArgonBox>
              </Grid>
              <Grid item xs={12} lg={9}>
                <ArgonBox>
                  <InputLabel sx={{ fontSize: "14px", mb: 0.25 }}>Package Name</InputLabel>
                  <ArgonInput value={name} onChange={(e) => setName(e.target.value)} />
                </ArgonBox>
                <ArgonBox mt={1}>
                  <InputLabel sx={{ fontSize: "14px", mb: 0.25 }}>Period</InputLabel>
                  <ArgonInput
                    type="number"
                    value={period}
                    onChange={(e) => setPeriod(e.target.value)}
                  />
                </ArgonBox>
                <ArgonBox mt={1}>
                  <InputLabel sx={{ fontSize: "14px", mb: 0.25 }}>Percentage</InputLabel>
                  <ArgonInput
                    type="number"
                    value={percent}
                    onChange={(e) => setPercent(e.target.value)}
                  />
                </ArgonBox>
              </Grid>
            </Grid>
            <ArgonBox mt={1}>
              <InputLabel sx={{ fontSize: "14px", mb: 0.25 }}>Withdraw Fee</InputLabel>
              <ArgonInput
                type="number"
                value={withdrawFee}
                onChange={(e) => setWithdrawFee(e.target.value)}
              />
            </ArgonBox>
            <ArgonBox mt={1}>
              <InputLabel sx={{ fontSize: "14px", mb: 0.25 }}>Status</InputLabel>
              <Select
                color="primary"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                sx={{
                  "& .MuiSelect-select": {
                    width: "100% !important",
                  },
                  "& .MuiSvgIcon-root": { display: "block" },
                  height: "40px !important",
                  paddingLeft: "0 !important",
                }}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>InActive</MenuItem>
              </Select>
            </ArgonBox>
            <ArgonBox mt={2} textAlign="right">
              <ArgonButton color="primary" onClick={handleAddPackage}>
                Add
              </ArgonButton>
              <ArgonButton color="dark" onClick={handleModalClose_add} sx={{ ml: 1 }}>
                Cancel
              </ArgonButton>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
        {/* </Card> */}
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}

export default SubscriptionPlan;
