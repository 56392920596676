
// prop-types is library for typechecking of props
import PropTypes from "prop-types";

import Divider from "@mui/material/Divider";
// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

function StatisticsItem({ title, color, value }) {
  return (   
    <ArgonBox>
        <ArgonTypography variant="h4" fontWeight="medium" mb={3}>
            {value}
        </ArgonTypography>
        <ArgonTypography variant="h5" fontWeight="medium" color="text" mb={3}>
            {title}
        </ArgonTypography>
        
        <Divider />
    </ArgonBox>
  );
}

// Setting default values for the props of StatisticsItem
StatisticsItem.defaultProps = {
  color: "info",
  value: "",
};

// Typechecking props for the StatisticsItem
StatisticsItem.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default StatisticsItem;
