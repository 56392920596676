import { useRef, useState } from "react";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import Grid from "@mui/material/Grid";
import defaultAvatar from "assets/images/logos/mastercard.png";
import { SERVER_URL } from "../../../constant";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";

export function DlgItem({ text, value, onChange }) {
  return (
    <Grid container spacing={3} mb={2}>
      <Grid item xs={12} md={6} lg={3}>
        <ArgonTypography>{text ? text : ""}</ArgonTypography>
      </Grid>
      <Grid item xs={12} md={6} lg={9}>
        <ArgonInput color="primary" value={value} onChange={onChange}></ArgonInput>
      </Grid>
    </Grid>
  );
}

export function ImageItem({ text, value, onChange }) {
  const imageRef = useRef(null);
  const [preview, setPreview] = useState(value ? SERVER_URL + value : null);

  const OnImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      onChange(selectedImage); // Update the state with the selected file

      const imageUrl = URL.createObjectURL(selectedImage); // Create a preview URL of the selected image
      setPreview(imageUrl);
    }
  };

  return (
    <Grid container spacing={3} mb={2}>
      <Grid item xs={12} md={6} lg={3}>
        <ArgonTypography>{text ? text : ""}</ArgonTypography>
      </Grid>
      <Grid item xs={12} md={6} lg={9}>
        <ArgonBox
          width="220px"
          height="160px"
          borderRadius="12px"
          border="1px solid #666666"
          p={1}
          textAlign="center"
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={() => {
            imageRef.current.click();
          }}
        >
          <input type="file" accept="image/*" onChange={OnImageChange} ref={imageRef} hidden />
          <ArgonBox
            component="img"
            src={preview ? preview : defaultAvatar}
            height="100%"
            borderRadius="8px"
          />
        </ArgonBox>
      </Grid>
    </Grid>
  );
}

export function SelectItem({ text, value, values, onChange }) {
  return (
    <Grid container spacing={3} mb={2}>
      <Grid item xs={12} md={6} lg={3}>
        <ArgonTypography>{text ? text : ""}</ArgonTypography>
      </Grid>
      <Grid item xs={12} md={6} lg={9}>
        <Select
          color="primary"
          value={value}
          onChange={onChange}
          sx={{
            "& .MuiSelect-select": {
              width: "100% !important",
            },
            "& .MuiSvgIcon-root": { display: "block" },
            height: "40px !important",
            paddingLeft: "0 !important",
          }}
        >
          {values &&
            values.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.key}
              </MenuItem>
            ))}
        </Select>
      </Grid>
    </Grid>
  );
}
