import { useState } from "react";
import { useMutation } from "react-query";
import { useStateWithCallbackLazy } from "use-state-with-callback";

// @mui material components
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Card from "@mui/material/Card";
import MUIDataTable from "mui-datatables";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

import { Lightbox } from "react-modal-image";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import SnackbarUtils from "utils/SnackbarUtils";
import { getKycs, updateKyc } from "service/apiCaller";

import defaultImage from "assets/images/default/default.jpg";
import { Switch } from "@mui/material";

function Kyc() {
  const [selectedId, setSelectedId] = useStateWithCallbackLazy(-1);
  const [rows, setRows] = useStateWithCallbackLazy([]);
  const [totalCount, setTotalCount] = useState(0);
  const [showLightBox, setShowLightBox] = useState(false);
  const [preview, setPreview] = useState(defaultImage);

  const handleOpenLightBox = (imageSrc) => {
    setShowLightBox(true);
    setPreview(imageSrc);
  };

  const handleCloseLightBox = () => {
    setShowLightBox(false);
    setPreview(defaultImage);
  };

  const getMutataion = useMutation(getKycs, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        const { count, rows } = response.data.data;
        setTotalCount(count);
        setRows(rows);
      }
    },
  });

  const updateTable = (tableState) => {
    let filterOptions = null;
    if (tableState) {
      let { searchText, page, rowsPerPage } = tableState;
      filterOptions = { searchText, page, rowsPerPage };
    }
    getMutataion.mutate(filterOptions);
  };

  const changeMutation = useMutation(updateKyc, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        const newRows = [...rows];
        newRows[selectedId] = { ...newRows[selectedId], status: !newRows[selectedId].status };
        setRows(newRows, () => {
          setSelectedId(-1);
        });
        SnackbarUtils.success(response.data.message);
      }
    },
  });

  const handleChangeStatus = (dataIndex) => {
    setSelectedId(dataIndex, (newSelectedId) => {
      const payload = {
        id: rows[newSelectedId].id,
        status: !rows[newSelectedId].status,
      };
      changeMutation.mutate(payload);
    });
  };

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        setCellHeaderProps: () => {
          return { align: "left" };
        },
        setCellProps: () => {
          return { align: "left" };
        },
      },
    },
    {
      name: "username",
      label: "Username",
      options: {
        setCellHeaderProps: () => {
          return { align: "left" };
        },
        setCellProps: () => {
          return { align: "left" };
        },
      },
    },
    {
      name: "selfie",
      label: "Selfie",
      options: {
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => {
          return { align: "right" };
        },
        customBodyRender: (value) => {
          const src = value ? value : defaultImage;
          return (
            <ArgonBox
              component="img"
              src={src}
              width="50px"
              sx={{ "&:hover": { cursor: "pointer" } }}
              onClick={() => handleOpenLightBox(src)}
            />
          );
        },
      },
    },
    {
      name: "card_type",
      label: "Card Type",
      options: {
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => {
          return { align: "right" };
        },
        customBodyRender: (value) => {
          if (value == "idcard_op") {
            return <ArgonTypography>ID Card</ArgonTypography>;
          } else if (value == "passport_op") {
            return <ArgonTypography>Passport</ArgonTypography>;
          } else {
            return <ArgonTypography>Driver License</ArgonTypography>;
          }
        },
      },
    },
    {
      name: "card_front",
      label: "Card Front",
      options: {
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => {
          return { align: "right" };
        },
        customBodyRender: (value) => {
          const src = value ? value : defaultImage;
          return (
            <ArgonBox
              component="img"
              src={src}
              width="50px"
              sx={{ "&:hover": { cursor: "pointer" } }}
              onClick={() => handleOpenLightBox(src)}
            />
          );
        },
      },
    },
    {
      name: "card_back",
      label: "Card Back",
      options: {
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => {
          return { align: "right" };
        },
        customBodyRender: (value) => {
          const src = value ? value : defaultImage;
          return (
            <ArgonBox
              component="img"
              src={src}
              width="50px"
              sx={{ "&:hover": { cursor: "pointer" } }}
              onClick={() => handleOpenLightBox(src)}
            />
          );
        },
      },
    },
    {
      name: "address_proof",
      label: "Address",
      options: {
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => {
          return { align: "right" };
        },
        customBodyRender: (value) => {
          const src = value ? value : defaultImage;
          return (
            <ArgonBox
              component="img"
              src={src}
              width="50px"
              sx={{ "&:hover": { cursor: "pointer" } }}
              onClick={() => handleOpenLightBox(src)}
            />
          );
        },
      },
    },
    {
      name: "status",
      label: "Proven",
      options: {
        setCellHeaderProps: () => {
          return { align: "center" };
        },
        setCellProps: () => {
          return { align: "center" };
        },
        customBodyRenderLite: (dataIndex) => {
          return (
            <Switch
              checked={rows[dataIndex].status}
              onChange={() => handleChangeStatus(dataIndex)}
            />
          );
        },
      },
    },
  ];

  const option = {
    responsive: "simple",
    selectableRows: "none",
    sort: false,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    search: true,
    serverSide: true,
    pageSize: 10,
    rowsPerPageOptions: [],
    count: totalCount,
    onTableInit: () => {
      updateTable(null);
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "search":
        case "changePage": {
          updateTable(tableState);
          break;
        }
        default:
          break;
      }
    },
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            root: {
              boxShadow: "none !important",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              "&:nth-of-type(7)": {
                width: 100,
              },
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              padding: "5px 12px !important",
            },
          },
        },
        MUIDataTableFooter: {
          styleOverrides: {
            root: {
              "& > tfoot > tr > td": {
                borderBottom: 0,
                padding: "0 16px !important",
              },
            },
          },
        },
        MuiChip: {
          styleOverrides: {
            root: {
              marginLeft: "8px !important",
            },
          },
        },
      },
    });

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card>
        <ArgonBox p={3}>
          <ArgonTypography
            variant="h3"
            style={{
              color: "#000000",
              fontSize: "24px",
              fontWeight: "bold",
              lineHeight: "32px",
            }}
          >
            KYC Infos
          </ArgonTypography>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable data={rows} columns={columns} options={option} />
          </ThemeProvider>
        </ArgonBox>
      </Card>

      {showLightBox && (
        <Lightbox
          medium={preview}
          large={preview}
          showRotate={true}
          onClose={handleCloseLightBox}
        />
      )}

      <Footer />
    </DashboardLayout>
  );
}

export default Kyc;
