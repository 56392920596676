import { useState, useEffect, useReducer } from "react";
// Argon Dashboard 2 MUI Components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import Dialog from "@mui/material/Dialog";
import { AddCircle } from "@mui/icons-material";
import List from "@mui/material/List";
import DialogTitle from "@mui/material/DialogTitle";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loader from "components/Loader/Loader";
import Footer from "examples/Footer";

import NetworkTable from "examples/Tables/Table";
import { generateRows, columns } from "./NetworkTableData";
import { DlgItem } from "examples/Tables/components/DlgComponents";
import { useAuthContext } from "context/auth";
import { useQuery } from "react-query";
import { getNetworks, createNetwork, editNetwork, deleteNetwork } from "service/apiCaller";
import {
  createMutation,
  editMutation,
  deleteMutation,
} from "examples/Tables/components/TableMutations";
import "assets/css/style.css";

function Network(props) {
  const { admin } = useAuthContext();
  const [openNetworkDialog, setOpenNetworkDialog] = useState(false);
  const [id, setId] = useState(0);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [url, setUrl] = useState("");
  const [explorer, setExplorer] = useState("");
  const [isTestnet, setIsTestnet] = useState("");
  const [dlgMode, setDlgMode] = useState(null);

  useEffect(() => {
    if (dlgMode === "DELETE") {
      if (confirm("Make sure to delete this network")) {
        onConfirmNetwork();
      }
    }
  }, [dlgMode, id]);

  let rows = [];

  const onCloseNetworkDialog = () => {
    setOpenNetworkDialog(false);
  };

  const onRowEdit = (e) => {
    setDlgMode("EDIT");
    setId(e.id);
    setAddress(e.address);
    setName(e.name);
    setUrl(e.url);
    setExplorer(e.explorer);
    setIsTestnet(Number(e.is_testnet));
    setOpenNetworkDialog(true);
  };

  const onRowDelete = (e) => {
    setDlgMode("DELETE");
    setId(e.id);
  };

  const { isLoading: isLoadingNetwork, data: response } = useQuery("network", getNetworks, {
    refetchOnWindowFocus: false,
  });
  if (response && response.data && response.data.data && response.data.data.length > 0) {
    rows = response.data.data;
  }

  const createNetworkMutation = createMutation(rows, createNetwork, setOpenNetworkDialog);
  const editNetworkMutation = editMutation(rows, editNetwork, setOpenNetworkDialog);
  const deleteNetworkMutation = deleteMutation(rows, deleteNetwork, setOpenNetworkDialog);

  const onConfirmNetwork = () => {
    const payload = {
      name,
      address,
      url,
      explorer,
      is_testnet: isTestnet,
    };

    switch (dlgMode) {
      case "CREATE":
        createNetworkMutation.mutate(payload);
        break;
      case "EDIT":
        payload.id = id;
        editNetworkMutation.mutate(payload);
        break;
      case "DELETE":
        deleteNetworkMutation.mutate(id);
        break;
    }
  };

  return (
    <DashboardLayout>
      {isLoadingNetwork && <Loader />}
      <Dialog
        open={openNetworkDialog}
        onClose={onCloseNetworkDialog}
        fullWidth={true}
        maxWidth="md"
        className="option-dlg"
      >
        <ArgonBox className="dlg-container">
          <DialogTitle>Set information of new network</DialogTitle>
          <List style={{ padding: "20px" }}>
            <DlgItem
              text={"Network Name"}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <DlgItem
              text={"Address"}
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
            <DlgItem
              text={"Url"}
              value={url}
              onChange={(e) => {
                setUrl(e.target.value);
              }}
            />
            <DlgItem
              text={"Explorer"}
              value={explorer}
              onChange={(e) => {
                setExplorer(e.target.value);
              }}
            />
            <DlgItem
              text={"Testnet? (0 or 1)"}
              value={isTestnet}
              onChange={(e) => {
                setIsTestnet(e.target.value);
              }}
            />
          </List>
          <ArgonBox style={{ display: "flex", justifyContent: "center" }}>
            <ArgonButton
              size="medium"
              color="primary"
              width={200}
              onClick={(e) => {
                onConfirmNetwork();
              }}
            >
              Confirm
            </ArgonButton>
          </ArgonBox>
        </ArgonBox>
      </Dialog>

      <DashboardNavbar />

      <ArgonButton
        color="primary"
        sx={{ px: 2 }}
        display="flex"
        onClick={() => {
          setDlgMode("CREATE");
          setAddress("");
          setName("");
          setUrl("");
          setExplorer("");
          setIsTestnet("");
          setOpenNetworkDialog(true);
        }}
      >
        <AddCircle sx={{ mr: 1 }} />
        New Network
      </ArgonButton>
      <ArgonBox mt={3} mb={3}>
        <NetworkTable columns={columns} rows={generateRows(rows, onRowEdit, onRowDelete)} />
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Network;
