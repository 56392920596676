import actionPack from '../actions/actions';

const actions = actionPack.admin;

const actionCreators = {
    getAdmins (payload) {
        return {
            type : actions.GET_ADMIN_LIST,
            payload : payload
        };
    },
};

export default actionCreators;