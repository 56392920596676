import { TableCell, TestNetCell, Action } from 'examples/Tables/components/CellComponents';

export const generateRows = (rows, handleEdit, handleDelete) => {
    return rows.map(row => (
        {
            Name: <TableCell text={row.name} ml={3} />,
            Address: <TableCell text={row.address} />,
            URL: <TableCell text={row.url} />,
            Explorer: <TableCell text={row.explorer} />,
            TestNet: <TestNetCell flag={row.is_testnet} />,
            "Action": <Action item={row} onEdit={handleEdit} onDelete={handleDelete} />
        }
    ))
}

export const columns = [
    { name: "Name", align: "left" },
    { name: "Address", align: "left" },
    { name: "URL", align: "left" },
    { name: "Explorer", align: "left" },
    { name: "MainNet", align: "center" },
    { name: "Action", align: "center" },
]
