import { useState, useEffect, useRef } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { useMutation } from "react-query";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import {
  CheckRounded,
  DeleteOutlineOutlined,
  PendingOutlined,
  SendRounded,
} from "@mui/icons-material";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Dialog, Divider } from "@mui/material";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import SnackbarUtils from "utils/SnackbarUtils";

import {
  getTickets,
  getTicket,
  completeTicket,
  updateTicket,
  deleteTicket,
} from "service/apiCaller";
import ChatMessages from "examples/Message";

function ContactSupport() {
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useStateWithCallbackLazy(null);
  const [showTicketModal, setShowTicketModal] = useStateWithCallbackLazy(false);
  const [ticketContent, setTicketContent] = useState("");

  const messagesEndRef = useRef(null);

  const getTicketsMutation = useMutation(getTickets, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        const rows = response.data.data.map((row) => ({
          ...row,
          name: row.user.first_name + " " + row.user.last_name,
          username: row.user.username,
        }));
        setData(rows);
      }
    },
  });

  useEffect(() => {
    getTicketsMutation.mutate();
  }, []);

  const scrollToBottom = (effect = true) => {
    if (effect) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      messagesEndRef.current?.scrollIntoView();
    }
  };

  const columns = [
    {
      name: "ticket_id",
      label: "Ticket ID",
      options: {
        sort: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        sort: false,
      },
    },
    {
      name: "username",
      label: "Username",
      options: {
        sort: false,
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        sort: false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return value === 0 ? <Chip label="Open" /> : <Chip label="Closed" />;
        },
      },
    },
    {
      name: "action",
      label: "Actions",
      options: {
        sort: false,
        empty: true,
        setCellHeaderProps: () => {
          return { align: "center" };
        },
        setCellProps: () => {
          return { align: "center" };
        },
        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              <IconButton
                color="info"
                value={dataIndex}
                onClick={() => handleShowTicket(dataIndex)}
                sx={{ padding: 0 }}
              >
                <PendingOutlined />
              </IconButton>
            </>
          );
        },
      },
    },
  ];

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            root: {
              boxShadow: "none !important",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              "&:nth-of-type(1)": {
                width: 100,
              },
              "&:nth-of-type(3)": {
                width: 100,
              },
              "&:nth-of-type(4)": {
                width: 100,
              },
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              padding: "5px 12px !important",
            },
          },
        },
        MUIDataTableFooter: {
          styleOverrides: {
            root: {
              "& > tfoot > tr > td": {
                borderBottom: 0,
                padding: "0 16px !important",
              },
            },
          },
        },
      },
    });

  const getTicketMutation = useMutation(getTicket, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        setSelectedData(response.data.data, () => {
          scrollToBottom(false);
        });
      }
    },
  });

  const handleShowTicket = (dataIndex) => {
    setShowTicketModal(true, () => {
      getTicketMutation.mutate({ id: data[dataIndex].id });
    });
  };

  const handleShowTicketModalClose = () => {
    setSelectedData(null);
    setTicketContent("");
    setShowTicketModal(false);
  };

  const updateTicketMutation = useMutation(updateTicket, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        setSelectedData(
          {
            ...selectedData,
            content: [
              ...selectedData.content,
              {
                author: "admin",
                msg: ticketContent,
              },
            ],
          },
          () => {
            scrollToBottom();
            setTicketContent("");
          }
        );
      }
    },
  });

  const handleUpdateTicket = () => {
    if (ticketContent.trim() != "") {
      const payload = {
        author: "admin",
        msg: ticketContent,
        id: selectedData.id,
      };

      updateTicketMutation.mutate(payload);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      handleUpdateTicket();
      event.preventDefault();
    }
  };

  const completeTicketMutation = useMutation(completeTicket, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        SnackbarUtils.success(response.data.message);
        handleShowTicketModalClose();
        getTicketsMutation.mutate();
      }
    },
  });

  const handleCompleteTicket = () => {
    const payload = {
      id: selectedData.id,
    };
    completeTicketMutation.mutate(payload);
  };

  const deleteTicketMutation = useMutation(deleteTicket, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        SnackbarUtils.success(response.data.message);
        handleShowTicketModalClose();
        getTicketsMutation.mutate();
      }
    },
  });

  const handleDeleteTicket = () => {
    const payload = {
      id: selectedData.id,
    };
    deleteTicketMutation.mutate(payload);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card>
        <ArgonBox px={4} py={5}>
          <ArgonTypography
            sx={{
              fontSize: "24px",
              lineHeight: "32px",
              fontWeight: "bold",
              color: "#000",
              mb: "8px",
            }}
          >
            Tickets
          </ArgonTypography>
          <ArgonBox mt={1}>
            <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                data={data}
                columns={columns}
                options={{
                  responsive: "simple",
                  selectableRows: "none",
                  filter: false,
                  print: false,
                  download: false,
                  viewColumns: false,
                  search: false,
                  pageSize: 10,
                  rowsPerPageOptions: [],
                }}
              />
            </ThemeProvider>
          </ArgonBox>
        </ArgonBox>
      </Card>

      <Dialog open={showTicketModal} onClose={handleShowTicketModalClose}>
        <ArgonBox p={4}>
          <ArgonBox display="flex" justifyContent="space-between" alignItems="center">
            <ArgonTypography variant="h6" component="h2">
              {selectedData ? selectedData.title : ""}
            </ArgonTypography>
            <ArgonBox>
              {selectedData && selectedData.status === 0 && (
                <IconButton color="success" onClick={handleCompleteTicket}>
                  <CheckRounded />
                </IconButton>
              )}
              <IconButton color="error" sx={{ mr: 1 }} onClick={handleDeleteTicket}>
                <DeleteOutlineOutlined />
              </IconButton>
            </ArgonBox>
          </ArgonBox>
          <Divider />
          <ArgonBox>
            <ArgonBox sx={{ overflowY: "auto", height: "350px" }}>
              {selectedData &&
                selectedData.content.map((item, index) => {
                  return (
                    <ChatMessages
                      key={index}
                      side={item.author === "admin" ? "right" : "left"}
                      messages={[item.msg]}
                    />
                  );
                })}
              <ArgonBox ref={messagesEndRef} />
            </ArgonBox>
            {selectedData && selectedData.status === 0 && (
              <ArgonBox mt={2} display="flex">
                <ArgonInput
                  multiline
                  placeholder="Type comment"
                  value={ticketContent}
                  onChange={(event) => setTicketContent(event.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <IconButton color="primary" onClick={handleUpdateTicket} sx={{ ml: 1 }}>
                  <SendRounded />
                </IconButton>
              </ArgonBox>
            )}
          </ArgonBox>
        </ArgonBox>
      </Dialog>

      <Footer />
    </DashboardLayout>
  );
}

export default ContactSupport;
