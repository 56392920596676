import React from 'react';
import { Routes, Route, Navigate }from 'react-router-dom';
import { connect } from 'react-redux'
import routes from 'routes'
import { useAuthContext } from 'context/auth';
import { mapStoreToProps, mapDispatchToProps, components } from 'store/storeToProps'
import SignIn from "layouts/authentication/sign-in/index";
// class RouterContainer extends React.PureComponent {
//     constructor (props, context) {
//         super(props, context);
//     }
function RouterContainer(props) {
    const { admin } = useAuthContext();
    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
        if (route.collapse) {
            return getRoutes(route.collapse);
        }
        if (route.route) {
            if (route.private) {
                if (admin.isAuthenticated) {
                    return <Route exact path={route.route} element={route.component} key={route.key} />;    
                } else {
                    <Route path="*" element={<Navigate to="/login" />} />
                }
            } else {
                return <Route exact path={route.route} element={route.component} key={route.key} />;
            }
            
        }

        return null;
    });
    
    return (
        <Routes>
            {
                getRoutes(routes)
            }
            <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
    )
}
// const WRouter = connect(
//     mapStoreToProps(components.AUTH),
//     mapDispatchToProps(components.AUTH)
// )(RouterContainer)
export default RouterContainer;
