import { TableCell, TableImageCell, Action } from 'examples/Tables/components/CellComponents';

export const generateRows = (rows, handleEdit, handleDelete) => {
    return rows.map(row => (
        {
            ID: <TableCell text={row.id} ml={3} />,
            Address: <TableCell text={row.address} />,
            "Name": <TableCell text={row.name} />,
            Symbol: <TableCell text={row.symbol} />,
            Decimal: <TableCell text={row.decimal} />,
            "image_url": <TableImageCell url={row.image_url} height={30} width={30} />,
            "Action": <Action item={row} onEdit={handleEdit} onDelete={handleDelete} />
        }
    ))
}

export const columns = [
    { name: "ID", align: "left" },
    { name: "Address", align: "left" },
    { name: "Name", align: "left" },
    { name: "Symbol", align: "left" },
    { name: "Decimal", align: "left" },
    { name: "image_url", align: "center" },
    { name: "Action", align: "center" },
]
