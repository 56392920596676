import { useState, useEffect, useReducer } from "react";
// Argon Dashboard 2 MUI Components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import Dialog from "@mui/material/Dialog";
import { AddCircle } from "@mui/icons-material";
import List from "@mui/material/List";
import DialogTitle from "@mui/material/DialogTitle";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loader from "components/Loader/Loader";
import Footer from "examples/Footer";

import PaymentOptionTable from "examples/Tables/Table";
import { generateRows, columns } from "./PaymentOptionTableData";
import { DlgItem, SelectItem } from "examples/Tables/components/DlgComponents";
import { useAuthContext } from "context/auth";
import { useMutation, useQuery } from "react-query";
import {
  getPaymentOptions,
  createPaymentOption,
  editPaymentOption,
  deletePaymentOption,
} from "service/apiCaller";
import {
  createMutation,
  editMutation,
  deleteMutation,
} from "examples/Tables/components/TableMutations";
import "assets/css/style.css";
import { ImageItem } from "examples/Tables/components/DlgComponents";
import { Divider, Grid, InputLabel } from "@mui/material";
import ArgonInput from "components/ArgonInput";
import { setSettings } from "service/apiCaller";
import SnackbarUtils from "utils/SnackbarUtils";
import { getSettings } from "service/apiCaller";

function PaymentOption(props) {
  const { admin } = useAuthContext();
  const [openPaymentOptionDialog, setOpenPaymentOptionDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(0);
  const [link, setLink] = useState("");
  const [name, setName] = useState("");
  const [logo_url, setLogoUrl] = useState("");
  const [option_type, setOptionType] = useState("");
  const [active_status, setActiveStatus] = useState("");
  const [dlgMode, setDlgMode] = useState(null);
  const [creditCardDlg, setCreditCardDlg] = useState(false);
  const [bankTransferDlg, setBankTransferDlg] = useState(false);
  const [bankInfo, setBankInfo] = useState({});

  let rows = [];

  useEffect(() => {
    if (dlgMode === "DELETE") {
      if (confirm("Make sure to delete this PaymentOption")) {
        onConfirmPaymentOption();
      }
    }
  }, [dlgMode, id]);

  const onClosePaymentOptionDialog = () => {
    setOpenPaymentOptionDialog(false);
  };

  const onRowEdit = (e) => {
    setDlgMode("EDIT");
    setId(e.id);
    setName(e.name);
    setLink(e.link);
    setLogoUrl(e.logo_url);
    setOptionType(e.type);
    setActiveStatus(Number(e.active_status));
    setOpenPaymentOptionDialog(true);
  };

  const onRowDelete = (e) => {
    setDlgMode("DELETE");
    setId(e.id);
  };

  const { isLoading: isLoadingPaymentOption, data: response } = useQuery(
    "PaymentOption",
    getPaymentOptions,
    { refetchOnWindowFocus: false }
  );
  if (response && response.data && response.data.data && response.data.data.length > 0) {
    rows = response.data.data;
  }

  const createPaymentOptionMutation = createMutation(
    rows,
    createPaymentOption,
    setOpenPaymentOptionDialog
  );
  const editPaymentOptionMutation = editMutation(
    rows,
    editPaymentOption,
    setOpenPaymentOptionDialog
  );
  const deletePaymentOptionMutation = deleteMutation(
    rows,
    deletePaymentOption,
    setOpenPaymentOptionDialog
  );

  const onConfirmPaymentOption = () => {
    const payload = new FormData();
    payload.append("link", link);
    payload.append("name", name);
    payload.append("active_status", active_status);
    payload.append("paymentoption", logo_url);
    payload.append("type", option_type);

    switch (dlgMode) {
      case "CREATE":
        createPaymentOptionMutation.mutate(payload);
        break;
      case "EDIT":
        payload.append("id", id);
        editPaymentOptionMutation.mutate(payload);
        break;
      case "DELETE":
        deletePaymentOptionMutation.mutate(id);
        break;
    }
  };

  const handleBankInfo = () => {
    switch (name) {
      case "Bank Transfer":
        getBankInfoMutation.mutate();
        break;
      default:
        break;
    }
  };

  const getBankInfoMutation = useMutation(getSettings, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        const info = JSON.parse(response.data.data.bank_info);
        setBankInfo({ ...bankInfo, ...info });
        setBankTransferDlg(true);
      }
    },
  });

  const saveBankInfoMutation = useMutation(setSettings, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        SnackbarUtils.success(response.data.message);
        setBankTransferDlg(false);
      }
    },
  });
  const handleBankInfoSave = () => {
    const payload = {
      bank_info: JSON.stringify(bankInfo),
    };
    saveBankInfoMutation.mutate(payload);
  };

  return (
    <DashboardLayout>
      {(isLoadingPaymentOption || isLoading) && <Loader />}
      <Dialog
        open={openPaymentOptionDialog}
        onClose={onClosePaymentOptionDialog}
        fullWidth={true}
        maxWidth="md"
        className="option-dlg"
      >
        <ArgonBox className="dlg-container">
          <DialogTitle>Set information of new PaymentOption</DialogTitle>
          <List style={{ padding: "20px" }}>
            <ImageItem
              text={"Logo"}
              value={logo_url}
              onChange={(e) => {
                setLogoUrl(e);
              }}
            />
            <DlgItem
              text={"Link"}
              value={link}
              onChange={(e) => {
                setLink(e.target.value);
              }}
            />
            <DlgItem
              text={"Name"}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <SelectItem
              text={"Type"}
              value={option_type}
              values={[
                { key: "Wallet", value: "Wallet" },
                { key: "Bank", value: "Bank" },
              ]}
              onChange={(e) => {
                setOptionType(e.target.value);
              }}
            />
            <SelectItem
              text={"Active Status"}
              value={active_status}
              values={[
                { key: "Active", value: 1 },
                { key: "InActive", value: 0 },
              ]}
              onChange={(e) => {
                setActiveStatus(e.target.value);
              }}
            />
          </List>
          <ArgonBox style={{ display: "flex", justifyContent: "center" }}>
            {option_type == "Bank" && (
              <ArgonButton color="success" onClick={handleBankInfo} sx={{ mr: 1 }}>
                Bank Information
              </ArgonButton>
            )}
            <ArgonButton
              size="medium"
              color="primary"
              width={200}
              onClick={(e) => {
                onConfirmPaymentOption();
              }}
            >
              Confirm
            </ArgonButton>
          </ArgonBox>
        </ArgonBox>
      </Dialog>

      <Dialog
        open={creditCardDlg}
        onClose={() => setCreditCardDlg(false)}
        sx={{ "& .MuiPaper-root": { width: 450 } }}
      >
        <ArgonBox p={3}>
          <ArgonTypography>Credit Card</ArgonTypography>
        </ArgonBox>
      </Dialog>

      <Dialog
        open={bankTransferDlg}
        onClose={() => setBankTransferDlg(false)}
        sx={{ "& .MuiPaper-root": { width: 500 } }}
      >
        <ArgonBox p={3}>
          <ArgonTypography>Bank Transfer</ArgonTypography>
          <Divider />
          <ArgonBox>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={6}>
                <InputLabel sx={{ fontSize: "14px" }}>Account Holder Name</InputLabel>
                <ArgonInput
                  value={bankInfo?.account_name || ""}
                  onChange={(e) => setBankInfo({ ...bankInfo, account_name: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputLabel sx={{ fontSize: "14px" }}>Account Number</InputLabel>
                <ArgonInput
                  value={bankInfo?.account_number || ""}
                  onChange={(e) => setBankInfo({ ...bankInfo, account_number: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputLabel sx={{ fontSize: "14px" }}>Bank Name</InputLabel>
                <ArgonInput
                  value={bankInfo?.bank_name || ""}
                  onChange={(e) => setBankInfo({ ...bankInfo, bank_name: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputLabel sx={{ fontSize: "14px" }}>Bank Address</InputLabel>
                <ArgonInput
                  value={bankInfo?.bank_address || ""}
                  onChange={(e) => setBankInfo({ ...bankInfo, bank_address: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputLabel sx={{ fontSize: "14px" }}>Bank Country</InputLabel>
                <ArgonInput
                  value={bankInfo?.bank_country || ""}
                  onChange={(e) => setBankInfo({ ...bankInfo, bank_country: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputLabel sx={{ fontSize: "14px" }}>Bank City</InputLabel>
                <ArgonInput
                  value={bankInfo?.bank_city || ""}
                  onChange={(e) => setBankInfo({ ...bankInfo, bank_city: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputLabel sx={{ fontSize: "14px" }}>Routing number(s)</InputLabel>
                <ArgonInput
                  value={bankInfo?.routing_number || ""}
                  onChange={(e) => setBankInfo({ ...bankInfo, routing_number: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <InputLabel sx={{ fontSize: "14px" }}>IBAN Code</InputLabel>
                <ArgonInput
                  value={bankInfo?.iban_code || ""}
                  onChange={(e) => setBankInfo({ ...bankInfo, iban_code: e.target.value })}
                />
              </Grid>
            </Grid>
            <ArgonBox textAlign="right" mt={2}>
              <ArgonButton color="primary" onClick={handleBankInfoSave}>
                Save
              </ArgonButton>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
      </Dialog>

      <DashboardNavbar />

      <ArgonButton
        color="primary"
        sx={{ px: 2 }}
        display="flex"
        onClick={() => {
          setDlgMode("CREATE");
          setName("");
          setLink("");
          setLogoUrl("");
          setOptionType("");
          setActiveStatus("");
          setOpenPaymentOptionDialog(true);
        }}
      >
        <AddCircle sx={{ mr: 1 }} />
        New PaymentOption
      </ArgonButton>
      <ArgonBox mt={3} mb={3}>
        <PaymentOptionTable columns={columns} rows={generateRows(rows, onRowEdit, onRowDelete)} />
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PaymentOption;
