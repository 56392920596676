import { useState, useEffect } from "react";
import { useMutation } from "react-query";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Tab, Tabs } from "@mui/material";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import TabPanel from "./components/TabPanel";
import General from "./components/TabPanel/General";
import Transaction from "./components/TabPanel/Transaction";

import { getSettings, setSettings } from "service/apiCaller";
import SnackbarUtils from "utils/SnackbarUtils";
import Referral from "./components/TabPanel/Referral";

function Settings() {
  const [tabValue, setTabValue] = useState(0);
  const [setting, setSetting] = useState(false);

  const getSettingMutation = useMutation(getSettings, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        setSetting(response?.data?.data);
      }
    },
  });
  const setSettingMutation = useMutation(setSettings, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        SnackbarUtils.success(response?.data?.message);
      }
    },
  });
  useEffect(() => {
    getSettingMutation.mutate();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card>
        <ArgonBox py={3} px={5}>
          <ArgonBox>
            <Tabs
              orientation="horizontal"
              value={tabValue}
              onChange={(event, newValue) => setTabValue(newValue)}
            >
              <Tab label="General" />
              <Tab label="Transaction" />
              <Tab label="Referral" />
              <Tab label="Mail" />
            </Tabs>
          </ArgonBox>
          <ArgonBox mt={3}>
            <TabPanel value={tabValue} index={0}>
              <General setting={setting} save={setSettingMutation} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Transaction setting={setting} save={setSettingMutation} />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <Referral />
            </TabPanel>
            <TabPanel value={tabValue} index={3}></TabPanel>
          </ArgonBox>
        </ArgonBox>
      </Card>

      <Footer />
    </DashboardLayout>
  );
}

export default Settings;
