
// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

import BalanceItem from "./BalanceItem";

function BalanceSummaryCard({ investmens_amount, deposits_amount, withdrawals_amount }) {
  return (
    <Card>
      <ArgonBox p={3} mx={3} display="flex" justifyContent="left">
        <ArgonTypography variant="body2" color="primary" fontWeight="bold">
          Balance Summary
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox pb={0} px={2} textAlign="center" lineHeight={1.25} >
        <Grid container spacing={3} mb={2}>
          <Grid item xs={12} md={6} lg={4}>
              <BalanceItem
                title="Investments"
                currency="USD"
                value={investmens_amount}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <BalanceItem
                title="Deposits"
                currency="USD"
                value={deposits_amount}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <BalanceItem
                title="Withdrawals"
                currency="USD"
                value={withdrawals_amount}
              />
            </Grid>
        </Grid>
        
      </ArgonBox>
    </Card>
  );
}

// Setting default values for the props of BalanceSummaryCard
BalanceSummaryCard.defaultProps = {
  investmens_amount: "2000",
  deposits_amount: "500",
  withdrawals_amount: "200"
};

// Typechecking props for the BalanceSummaryCard
BalanceSummaryCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  investmens_amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  deposits_amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withdrawals_amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default BalanceSummaryCard;
