// Argon Dashboard 2 MUI layouts
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Dashboard from "layouts/dashboard";
import Coin from "layouts/coin";
import Network from "layouts/network";
import PaymentOption from "layouts/payment-option";
import Admin from "layouts/admin";
import User from "layouts/user";
import Settings from "layouts/settings";
import SubscriptionPlan from "layouts/subscription-plan";
import ContactSupport from "layouts/contact-support";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";

import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import Diversity1OutlinedIcon from "@mui/icons-material/Diversity1Outlined";
import CurrencyBitcoinOutlinedIcon from "@mui/icons-material/CurrencyBitcoinOutlined";
import LanOutlinedIcon from "@mui/icons-material/LanOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import EmojiPeopleOutlinedIcon from "@mui/icons-material/EmojiPeopleOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import VolunteerActivismOutlinedIcon from "@mui/icons-material/VolunteerActivismOutlined";
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import MoneyOffOutlinedIcon from "@mui/icons-material/MoneyOffOutlined";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import SettingsAccessibilityOutlinedIcon from "@mui/icons-material/SettingsAccessibilityOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import Affiliates from "layouts/affiliates";
import MySubscription from "layouts/subscription";
import Kyc from "layouts/kyc";
import Withdrawal from "layouts/withdrawal";
import Transaction from "layouts/transaction";

const routes = [
  {
    isMenu: true,
    type: "route",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    private: true,
    icon: <DashboardOutlinedIcon color="primary" />,
    component: <Dashboard />,
  },
  // {
  //   isMenu: true,
  //   type: "route",
  //   name: "Notifications",
  //   key: "notifications",
  //   route: "/notifications",
  //   icon: <NotificationsActiveOutlinedIcon color="primary" />,
  // },
  {
    isMenu: true,
    type: "route",
    name: "Support-Center",
    key: "support-center",
    route: "/support-center",
    icon: <SupportAgentOutlinedIcon color="primary" />,
    component: <ContactSupport />,
  },
  {
    isMenu: true,
    type: "divider",
    key: "divider-1",
  },
  // {
  //   isMenu: true,
  //   type: "route",
  //   name: "Profile",
  //   key: "profile",
  //   route: "/profile",
  //   private: true,
  //   icon: <FactCheckOutlinedIcon color="primary" />,
  // },
  {
    isMenu: true,
    type: "route",
    name: "Admins",
    key: "admins",
    route: "/admins",
    private: true,
    icon: <SupervisorAccountOutlinedIcon color="primary" />,
    component: <Admin />,
  },
  {
    isMenu: true,
    type: "route",
    name: "Users",
    key: "users",
    route: "/users",
    private: true,
    icon: <EmojiPeopleOutlinedIcon color="primary" />,
    component: <User />,
  },

  {
    isMenu: true,
    type: "route",
    name: "KYC",
    key: "kyc",
    route: "/kyc",
    icon: <SettingsAccessibilityOutlinedIcon color="primary" />,
    component: <Kyc />,
  },
  // {
  //   isMenu: true,
  //   type: "route",
  //   name: "User Activities",
  //   key: "activities",
  //   route: "/activities",
  //   icon: <HistoryOutlinedIcon color="primary" />,
  // },
  {
    isMenu: true,
    type: "divider",
    key: "divider-2",
  },
  {
    isMenu: true,
    type: "route",
    name: "Networks",
    key: "networks",
    route: "/networks",
    private: true,
    icon: <LanOutlinedIcon color="primary" />,
    component: <Network />,
  },
  {
    isMenu: true,
    type: "route",
    name: "Coins",
    key: "coins",
    route: "/coins",
    private: true,
    icon: <CurrencyBitcoinOutlinedIcon color="primary" />,
    component: <Coin />,
  },
  {
    isMenu: true,
    type: "route",
    name: "Subscription Plan",
    key: "subscription-plan",
    route: "/subscription-plan",
    icon: <MonitorHeartOutlinedIcon color="primary" />,
    component: <SubscriptionPlan />,
  },
  {
    isMenu: true,
    type: "route",
    name: "Payment Options",
    key: "payment-options",
    route: "/payment-options",
    private: true,
    icon: <PaymentOutlinedIcon color="primary" />,
    component: <PaymentOption />,
  },
  {
    isMenu: true,
    type: "divider",
    key: "divider-3",
  },
  {
    isMenu: true,
    type: "route",
    name: "Subscriptions",
    key: "subscriptions",
    route: "/subscriptions",
    icon: <VolunteerActivismOutlinedIcon color="primary" />,
    component: <MySubscription />,
  },
  {
    isMenu: true,
    type: "route",
    name: "Withdrawals",
    key: "withdrawals",
    route: "/withdrawals",
    icon: <MoneyOffOutlinedIcon color="primary" />,
    component: <Withdrawal />,
  },
  {
    isMenu: true,
    type: "route",
    name: "Deposit",
    key: "transaction",
    route: "/transaction",
    icon: <MoneyOffOutlinedIcon color="primary" />,
    component: <Transaction />,
  },
  {
    isMenu: true,
    type: "route",
    name: "Affiliates",
    key: "affiliates",
    route: "/affiliates",
    icon: <Diversity1OutlinedIcon color="primary" />,
    component: <Affiliates />,
  },
  {
    isMenu: true,
    type: "divider",
    key: "divider-4",
  },
  {
    isMenu: true,
    type: "route",
    name: "Settings",
    key: "settings",
    route: "/settings",
    icon: <HandymanOutlinedIcon color="primary" />,
    component: <Settings />,
  },
  {
    type: "route",
    name: "Sign In",
    key: "sign-in",
    route: "/login",
    icon: (
      <ArgonBox component="i" color="warning" fontSize="14px" className="ni ni-single-copy-04" />
    ),
    component: <SignIn />,
  },
  {
    type: "route",
    name: "Sign Up",
    key: "sign-up",
    route: "/register",
    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-collection" />,
    component: <SignUp />,
  },
];

export default routes;
