import React, { createContext, useEffect, useState } from 'react';
import { useAuthContext } from "context/auth";
import io from 'socket.io-client';

export const SocketContext = createContext();

// Create the provider component
export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const { admin } = useAuthContext();
  useEffect(() => {
    // Connect to the server
    const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
        query: {token: `${admin?.token?.token? admin.token.token : ''}`, isAdmin: 1, admin_uuid: admin.uuid},
    });
    setSocket(newSocket);
    // Clean up on unmount
    // return () => {
    //   newSocket.disconnect();
    // };
  }, []);

  return (  
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};