import http from "./http";
const apiEndpoints = {
  auth: {
    check: "/auth/check",
    register: "/auth/register",
    login: "/auth/login",
    refreshToken: "/auth/refresh-token",
    changePassword: "/auth/change-password",
    logout: "/auth/logout",
  },
  wallet: {
    getAllCoins: "/coin/list",
    getCoinsByNetwork: "/coin/list",
    controlCoin: "/coin",
    getNetworks: "/network/list",
    controlNetwork: "/network",
    getPaymentOptions: "/paymentoptions/list",
    controlPaymentOption: "/paymentoptions",
    confirmDeposit: "/wallet/deposit",
  },
  user: {
    saveProfile: "/user/profile/save",
    getKycs: "/user/kyc/list",
    updateKyc: "/user/kyc/update",
    getUsers: "/user/list",
  },
  admin: {
    getAdmins: "/admin/list",
  },
  withdraw: {
    getWithdraws: "/withdraw/list",
    confirmWithdraw: "/withdraw/confirm"
  },
  transaction: {
    getTransactions: "/transactions/list",
    getAllTransCoins: "/coin/list",
    confirm: "/transactions/confirm",
  },
  contact: {
    getTickets: "/contact/list",
    getTicket: "/contact/get",
    updateTicket: "/contact/update",
    completeTicket: "/contact/complete",
    deleteTicket: "/contact/delete",
  },
  affiliate: {
    getAffiliates: "/affiliate/list",
    getReferralLevels: "/affiliate/levels/list",
    saveReferralLevels: "/affiliate/levels/save",
  },
  subscription: {
    getInvestments: "/subscription/list",
    getPackages: "/subscription/package/list",
    savePackage: "/subscription/package/save",
    addPackage: "/subscription/package/add",
    deletePackage: "/subscription/package/delete",
    activateInvestment: "/subscription/investment/activate",
    pauseInvestment: "/subscription/investment/pause",
    deleteInvestment: "/subscription/investment/delete",
  },
  setting: {
    getSettings: "/setting/get",
    setSettings: "/setting/set",
  },
  notification: {
    getAllNotifications: "/notification/all",
    readMark: "/notification/readmark",
    addNewNotification: "/notification/add"
  }
};
const { auth, wallet, user, admin, contact, affiliate, subscription, setting, withdraw , notification, transaction} =
  apiEndpoints;
export const login = (payload) => http.post(auth.login, payload);
export const logout = (payload) => http.post(auth.logout, payload);
export const refreshToken = (payload) => http.post(auth.refreshToken, payload);
export const registration = (payload) => http.post(auth.register, payload);
export const changePassword = (payload) => http.put(auth.changePassword, payload);
export const check = (payload) => http.post(auth.check, payload);
export const getCoinsByNetwork = ({ queryKey }) => {
  const [_, networkId] = queryKey;
  return http.get(wallet.getCoinsByNetwork + "/" + networkId);
};

export const getAllCoins = () => http.get(wallet.getAllCoins);
export const createCoin = (payload) => http.post(wallet.controlCoin, payload);
export const editCoin = (payload) => http.put(wallet.controlCoin, payload);
export const deleteCoin = (id) => http.delete(wallet.controlCoin + "/" + id);
export const getNetworks = () => http.get(wallet.getNetworks);
export const createNetwork = (payload) => http.post(wallet.controlNetwork, payload);
export const editNetwork = (payload) => http.put(wallet.controlNetwork, payload);
export const deleteNetwork = (id) => http.delete(wallet.controlNetwork + "/" + id);
export const getPaymentOptions = () => http.get(wallet.getPaymentOptions);
export const createPaymentOption = (payload) => http.post(wallet.controlPaymentOption, payload);
export const editPaymentOption = (payload) => http.put(wallet.controlPaymentOption, payload);
export const deletePaymentOption = (id) => http.delete(wallet.controlPaymentOption + "/" + id);
export const getUsers = (payload) => http.post(user.getUsers, payload);
export const getAdmins = () => http.get(admin.getAdmins);
export const saveProfile = (payload) => http.post(user.saveProfile, payload);
export const getKycs = (payload) => http.post(user.getKycs, payload);
export const updateKyc = (payload) => http.put(user.updateKyc, payload);

export const getWithdraws = (payload) => http.post(withdraw.getWithdraws, payload);
export const confirmWithdraw = (payload) => http.put(withdraw.confirmWithdraw, payload);

export const confirmDeposit = (payload) => http.put(wallet.confirmDeposit, payload);

export const getTickets = () => http.get(contact.getTickets);
export const getTicket = (payload) => http.post(contact.getTicket, payload);
export const completeTicket = (payload) => http.put(contact.completeTicket, payload);
export const updateTicket = (payload) => http.put(contact.updateTicket, payload);
export const deleteTicket = (payload) => http.post(contact.deleteTicket, payload);

export const getAffiliates = (payload) => http.post(affiliate.getAffiliates, payload);
export const getReferralLevels = () => http.get(affiliate.getReferralLevels);
export const saveReferralLevels = (payload) => http.put(affiliate.saveReferralLevels, payload);

export const getInvestments = (payload) => http.post(subscription.getInvestments, payload);
export const getPackages = () => http.get(subscription.getPackages);
export const savePackage = (payload) => http.put(subscription.savePackage, payload);
export const addPackage = (payload) => http.put(subscription.addPackage, payload);
export const deletePackage = (payload) => http.put(subscription.deletePackage, payload);
export const activateInvestment = (payload) => http.put(subscription.activateInvestment, payload);
export const pauseInvestment = (payload) => http.put(subscription.pauseInvestment, payload);
export const deleteInvestment = (payload) => http.put(subscription.deleteInvestment, payload);

export const getSettings = () => http.get(setting.getSettings);
export const setSettings = (payload) => http.put(setting.setSettings, payload);

// *** get all notifications  *** //
export const getAllNotifications = () => http.get(notification.getAllNotifications);
export const setReadMark = (payload) => http.put(notification.readMark, payload);
export const addNewNotification = (payload) => http.post(notification.addNewNotification, payload);

export const getAllTransCoins = (payload) => http.get(transaction.getAllCoins, payload);
export const getTransactions = (payload) => http.post(transaction.getTransactions, payload);
export const confirmTransaction = (payload) => http.post(transaction.confirm, payload);