const initialState = {
    auth: {
        admin: undefined,
        isAuthenticated: false
    },
    wallet: {
        coins: [],
        networks: [],
        paymentOptions: []
    },
    user: {
        users: [],
    },
    admin: {
        admins: [],
    }
}
export default initialState