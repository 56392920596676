function navbar(theme) {
  const { transitions } = theme;

  return {
      position: "fixed",
      color: "inherit",

      "& > *": {
          transition: transitions.create("all", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
          }),
      },
  };
}

const navbarContainer = ({ breakpoints }) => ({
  height: "80px",
  background: "white",
  justifyContent: "space-between",
  alignItems: "center",

  [breakpoints.down("xl")]: {
      paddingLeft: "24px",
  },

  [breakpoints.up("xl")]: {
      paddingLeft: "324px",
  },
});

const navbarRow = ({ breakpoints }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  "& .MuiInputBase-root": {
      borderColor: "#666666",
      borderRadius: "12px",
      width: "251px",
      height: "38px",
      mr: 1,

      [breakpoints.down("sm")]: {
          display: "none !important",
      },
  },
});

const navbarIconButton = ({ typography: { size }, breakpoints }) => ({
  px: 0.75,

  "& .material-icons, .material-icons-round": {
      fontSize: `${size.md} !important`,
  },

  "& .MuiTypography-root": {
      display: "none",

      [breakpoints.up("sm")]: {
          display: "inline-block",
          lineHeight: 1.2,
          ml: 0.5,
      },
  },
});

const navbarDesktopMenu = ({ breakpoints }) => ({
  display: "none !important",
  cursor: "pointer",

  [breakpoints.up("xl")]: {
      display: "inline-block !important",
  },
});

const navbarMobileMenu = ({ breakpoints }) => ({
  display: "inline-block",
  lineHeight: 0,

  [breakpoints.up("xl")]: {
      display: "none",
  },
});

export {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
};
