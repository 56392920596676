// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    opacity: 0;
    border-radius: 10px;
    background-color: transparent;
    background-clip: content-box;
}
*:hover::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
    background-clip: content-box;
}
*:hover::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
}
*::-webkit-scrollbar-thumb:hover {
    background-color: #999999;
}

.select-network {
    width: 300px;
    float: right;
    margin-bottom: 20px;
}
.option-dlg {
    padding-bottom: 40px;
}
.option-dlg span.active{
    color: #4fc47a;
    font-size: 14px;
}
.option-dlg span.comming-soon {
    color: red;
    font-size: 12px;
}
.option-dlg .err-msg p{
    padding: 10px 20px;
    color: red;
}
.option-dlg .err-msg a {
    padding-left: 20px;
}
.option-dlg .deposit-details {
    padding: 20px;
}
.option-dlg .deposit-details p{
    font-size: 16px;
}
.option-dlg .dlg-container {
    padding: 20px 20px 40px 20px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/style.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX,6BAA6B;IAC7B,mBAAmB;AACvB;AACA;IACI,UAAU;IACV,mBAAmB;IACnB,6BAA6B;IAC7B,4BAA4B;AAChC;AACA;IACI,UAAU;IACV,WAAW;IACX,yBAAyB;IACzB,4BAA4B;AAChC;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,mBAAmB;AACvB;AACA;IACI,oBAAoB;AACxB;AACA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,UAAU;IACV,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,UAAU;AACd;AACA;IACI,kBAAkB;AACtB;AACA;IACI,aAAa;AACjB;AACA;IACI,eAAe;AACnB;AACA;IACI,4BAA4B;AAChC","sourcesContent":["::-webkit-scrollbar {\n    width: 6px;\n    height: 6px;\n    background-color: transparent;\n    border-radius: 10px;\n}\n::-webkit-scrollbar-thumb {\n    opacity: 0;\n    border-radius: 10px;\n    background-color: transparent;\n    background-clip: content-box;\n}\n*:hover::-webkit-scrollbar {\n    width: 6px;\n    height: 6px;\n    background-color: #f5f5f5;\n    background-clip: content-box;\n}\n*:hover::-webkit-scrollbar-thumb {\n    background-color: #dcdcdc;\n}\n*::-webkit-scrollbar-thumb:hover {\n    background-color: #999999;\n}\n\n.select-network {\n    width: 300px;\n    float: right;\n    margin-bottom: 20px;\n}\n.option-dlg {\n    padding-bottom: 40px;\n}\n.option-dlg span.active{\n    color: #4fc47a;\n    font-size: 14px;\n}\n.option-dlg span.comming-soon {\n    color: red;\n    font-size: 12px;\n}\n.option-dlg .err-msg p{\n    padding: 10px 20px;\n    color: red;\n}\n.option-dlg .err-msg a {\n    padding-left: 20px;\n}\n.option-dlg .deposit-details {\n    padding: 20px;\n}\n.option-dlg .deposit-details p{\n    font-size: 16px;\n}\n.option-dlg .dlg-container {\n    padding: 20px 20px 40px 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
