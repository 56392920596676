import { useEffect, useState } from "react";
import { useMutation } from "react-query";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { getAffiliates } from "service/apiCaller";
import moment from "moment";

function Affiliates() {
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        setCellHeaderProps: () => {
          return { align: "left" };
        },
        setCellProps: () => {
          return { align: "left" };
        },
      },
    },
    {
      name: "username",
      label: "Username",
      options: {
        setCellHeaderProps: () => {
          return { align: "left" };
        },
        setCellProps: () => {
          return { align: "left" };
        },
      },
    },
    {
      name: "sponsor",
      label: "Sponsor By",
      options: {
        setCellHeaderProps: () => {
          return { align: "left" };
        },
        setCellProps: () => {
          return { align: "left" };
        },
      },
    },
    {
      name: "level",
      label: "Level",
      options: {
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => {
          return { align: "right" };
        },
      },
    },
    {
      name: "percent",
      label: "Incentives",
      options: {
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => {
          return { align: "right" };
        },
        customBodyRender: (value) => value + "%",
      },
    },
    {
      name: "earning",
      label: "Amount Earned",
      options: {
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => {
          return { align: "right" };
        },
      },
    },
    {
      name: "investment",
      label: "Subscription",
      options: {
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => {
          return { align: "right" };
        },
      },
    },
    {
      name: "createdAt",
      label: "Date Registered",
      options: {
        setCellHeaderProps: () => {
          return { align: "center" };
        },
        setCellProps: () => {
          return { align: "center" };
        },
        customBodyRender: (value) => {
          return moment(value).format("DD-MM-YYYY");
        },
      },
    },
  ];

  const option = {
    responsive: "simple",
    selectableRows: "none",
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    search: true,
    pageSize: 10,
    serverSide: true,
    count: total,
    onTableInit: (action, tableState) => {
      updateTable(tableState);
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "tableInitialized":
        case "search":
        case "sort":
        case "changePage":
        case "changeRowsPerPage": {
          updateTable(tableState);
          break;
        }
        default:
          break;
      }
    },
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            root: {
              boxShadow: "none !important",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              padding: "5px 12px !important",
            },
          },
        },
        MUIDataTableFooter: {
          styleOverrides: {
            root: {
              "& > tfoot > tr > td": {
                borderBottom: 0,
                padding: "0 16px !important",
              },
            },
          },
        },
      },
    });

  const mutation = useMutation(getAffiliates, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        setTotal(response?.data?.data?.count);
        setData(response?.data?.data?.rows);
      }
    },
  });

  const updateTable = (tableState) => {
    let filterOptions = { ...tableState };
    mutation.mutate(filterOptions);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card>
        <ArgonBox p={3}>
          <ArgonTypography
            variant="h3"
            style={{
              color: "#000000",
              fontSize: "24px",
              fontWeight: "bold",
              lineHeight: "32px",
            }}
          >
            Downlines
          </ArgonTypography>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable data={data} columns={columns} options={option} />
          </ThemeProvider>
        </ArgonBox>
      </Card>

      <Footer />
    </DashboardLayout>
  );
}

export default Affiliates;
