import { useState } from "react";

import { Switch } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

function General({ setting, save }) {
  const [emailConfirmation, setEmailConfirmation] = useState(setting.two_factor);

  const handleClickSave = () => {
    const payload = {
      two_factor: emailConfirmation,
    };

    save.mutate(payload);
  };

  return (
    <ArgonBox>
      <ArgonBox>
        <ArgonTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
          Registration Settings
        </ArgonTypography>
        <ArgonBox display="flex" mb={0.25}>
          <ArgonBox mt={0.25}>
            <Switch
              checked={emailConfirmation}
              onChange={() => setEmailConfirmation(!emailConfirmation)}
            />
          </ArgonBox>
          <ArgonBox width="80%" ml={2}>
            <ArgonTypography variant="button" fontWeight="regular" color="text">
              Email Confirmation
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>

      <ArgonBox mt={3} textAlign="right">
        <ArgonButton color="primary" onClick={handleClickSave}>
          Save
        </ArgonButton>
      </ArgonBox>
    </ArgonBox>
  );
}

export default General;
