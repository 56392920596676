import { useState } from "react";
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import bgImage from "assets/images/auth-bg.png";
import { mapStoreToProps, mapDispatchToProps, components } from "store/storeToProps";
import { Checkbox, InputLabel } from "@mui/material";
import { connect } from "react-redux";
import { useAuthContext } from "context/auth";
import { useMutation } from "react-query";
// import history from 'store/history';
import "../auth.css";
import { login } from "service/apiCaller";

function Illustration(props) {
  const { admin, setAdmin } = useAuthContext();
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const mutation = useMutation(login, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        props.login(response);
        setAdmin({
          ...admin,
          ...response?.data?.data,
          isAuthenticated: true,
          token: response?.data?.tokens?.access,
          refreshToken: response?.data?.tokens?.refresh,
          tokenLife: Math.floor(Date.now() / 1000),
        });
        window.location.replace("/dashboard");
      }
    },
  });
  const { isSuccess, isLoading, isError, error: serverError, data: serverResponse } = mutation;
  const validate = () => {
    let errors = {};
    let isValid = true;

    if (email === "") {
      isValid = false;
      errors["email"] = "Please enter email address.";
    }
    if (email !== "") {
      let pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        isValid = false;
        errors["email"] = "Please enter a valid email address.";
      }
    }
    if (password === "") {
      isValid = false;
      errors["password"] = "Please enter password.";
    }
    setError(errors);
    return isValid;
  };
  const onClickLogin = () => {
    if (validate()) {
      const postData = {
        email: email,
        password: password,
      };
      mutation.mutate(postData);
    }
  };
  const onClickEnter = (e) => {
    if (e.key === 'Enter') 
    {
      if (validate()) {
        const postData = {
          email: email,
          password: password,
        };
        mutation.mutate(postData);
      }
    }
  }

  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter your email and password to sign in"
      illustration={{
        image: bgImage,
        title: '"Attention is the new currency"',
        description:
          "The more effortless the writing looks, the more effort the writer actually put into the process.",
      }}
    >
      <ArgonBox component="form" role="form">
        {isSuccess && serverResponse.status === "401" ? (
          <div className="getError_md_start">
            <p>{serverResponse?.response?.data?.message}</p>
          </div>
        ) : (
          isError && (
            <div className="getError_md_start">
              <p>{serverError?.response?.data?.message}</p>
            </div>
          )
        )}
        <ArgonBox mb="12px">
          <InputLabel
            style={{
              fontSize: "14px",
              lineHeight: "19px",
              fontWeight: "700",
              color: "#717579",
              marginBottom: "4px",
            }}
          >
            Email
          </InputLabel>
          <ArgonInput
            type="email"
            placeholder="Email"
            size="large"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyPress={(e) => onClickEnter(e)}
          />
          {error.email && (
            <div className="getError_md_start">
              <p>{error.email}</p>
            </div>
          )}
        </ArgonBox>
        <ArgonBox mb={2}>
          <InputLabel
            style={{
              fontSize: "14px",
              lineHeight: "19px",
              fontWeight: "700",
              color: "#717579",
              marginBottom: "4px",
            }}
          >
            Password
          </InputLabel>
          <ArgonInput
            type="password"
            placeholder="Password"
            size="large"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={(e) => onClickEnter(e)}
          />
          {error.password && (
            <div className="getError_md_start">
              <p>{error.password}</p>
            </div>
          )}
        </ArgonBox>
        <ArgonBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <ArgonTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;Remember me
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox mt={3}>
          <ArgonButton
            color="info"
            size="large"
            fullWidth
            onClick={onClickLogin}
            disabled={isLoading}
          >
            Log In
          </ArgonButton>
        </ArgonBox>
      </ArgonBox>
    </IllustrationLayout>
  );
}

// export default Illustration;
const WIndicatorPanel = connect(
  mapStoreToProps(components.AUTH),
  mapDispatchToProps(components.AUTH)
)(Illustration);
export default WIndicatorPanel;
