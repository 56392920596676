import initialState from '../initialState';
import actionPack from '../actions/actions';
const actions = actionPack.admin;

export default function adminReducer(state=initialState.admin, action) {
    switch (action.type) {
        case actions.GET_ADMIN_LIST:
            return {
                ...state,
                admins: action.payload
            };
      default:
            return state;
    }
  }