import { useEffect, useState, useContext } from "react";

// react-router components
import { useLocation } from "react-router-dom";
import { SocketContext } from "context/socket";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import { useMutation } from "react-query";

// @mui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Badge from "@mui/material/Badge";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonInput from "components/ArgonInput";

// Argon Dashboard 2 MUI example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Argon Dashboard 2 MUI context
import { useArgonController, setMiniSidenav } from "context";

// Images
import CustomizedMenus from "../Menu";
import SnackbarUtils from "utils/SnackbarUtils";
import { 
  getAllNotifications, 
  setReadMark,
  addNewNotification
} from "service/apiCaller";

const NotiIcon = (str) => {
  switch (str) {
    case "WITHDRAW":
      var noti_obj = {
        color: 'info',
        icon: 'money',
        msg: 'requested'
      }
      return noti_obj;
    case "DEPOSITE":
      var noti_obj = {
        color: 'error',
        icon: 'payment',
        msg: 'requested'
      }
      return noti_obj;
    case "SIGNUP":
      var noti_obj = {
        color: 'primary',
        icon: 'groupadd',
        msg: ''
      }
      return noti_obj;
  }
};

function DashboardNavbar({ title, isMini }) {
  const [controller, dispatch] = useArgonController();
  const { miniSidenav } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget, mutationNotification.mutate());

  const socket = useContext(SocketContext);

  // mark about read notification
  const setReaMark = useMutation(setReadMark, {
    onSuccess: (response) => {
      if (response?.data?.status) {        
        mutationNotification.mutate();
      }
    },
  });

  // added by hazer
  const [notificationsPending, setPendingUsers] = useState([]);

  const mutationNotification = useMutation(getAllNotifications, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        setPendingUsers(response.data.data);
      }
    },
  });

  const addMutationNotification = useMutation(addNewNotification, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        mutationNotification.mutate();
      }
    }
  })

  useEffect(() => {
    if (socket) {
      socket.on("admin-withdraw-request", function (soket_data) {
        addMutationNotification.mutate(soket_data);
        SnackbarUtils.success("New Withdraw request arrived!");
      });
    }
    mutationNotification.mutate();
  }, [socket]);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={() => setOpenMenu(false)}
      sx={{ mt: 2 }}
    >
      {notificationsPending &&
        notificationsPending.map((value, index) => (
          <NotificationItem
            key={index}
            color={NotiIcon(value.notification_type).color}
            image={
              <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
                {NotiIcon(value.notification_type).icon}
              </Icon>
            }
            title={[
              "",
              `${value.user_name} ${NotiIcon(value.notification_type).msg} ${value.notification_type}!`,
            ]}
            date={`${value.createdAt}`}
            onClick={() => setReaMark.mutate({ noti_id: value.id })}
          />
        ))}
    </Menu>
  );

  return (
    <AppBar sx={navbar}>
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <ArgonBox color="dark">
          <Breadcrumbs
            icon="home"
            title={title && title != "" ? title : route[route.length - 1]}
            route={route}
          />
        </ArgonBox>
        {isMini ? null : (
          <ArgonBox sx={(theme) => navbarRow(theme)}>
            <ArgonInput
              placeholder="Search"
              startAdornment={
                <Icon fontSize="small" style={{ marginRight: "10px" }}>
                  search
                </Icon>
              }
            />
            <ArgonBox display="flex">
              <IconButton
                size="small"
                color="dark"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon>{miniSidenav ? "menu_open" : "menu"}</Icon>
              </IconButton>

              <Badge badgeContent={notificationsPending.length} color="error">
                <IconButton
                  color="dark"
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                >
                  <Icon sx={{ fontSize: 30 }}>notifications</Icon>
                </IconButton>
              </Badge>
              <CustomizedMenus />

              {renderMenu()}
            </ArgonBox>
          </ArgonBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: true,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
