import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import bgImage from "assets/images/auth-bg.png";
import { connect } from "react-redux";
import { mapStoreToProps, mapDispatchToProps, components } from "store/storeToProps";
import { Checkbox, InputLabel } from "@mui/material";
import { useMutation } from "react-query";
import { useAuthContext } from "context/auth";
import { registration, login } from "service/apiCaller";
import "../auth.css";
function Illustration(props) {
  const { user, setUser } = useAuthContext();
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [error, setError] = useState("");
  const handleSetRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setReferralCode(params.referral_code);
  }, []);

  const loginmutation = useMutation(login, {
    onSuccess: (response) => {
      if (response.data.status) {
        props.login(response);
        setUser({
          ...user,
          ...response?.data?.data,
          isAuthenticated: true,
          token: response?.data?.tokens?.access,
          refreshToken: response?.data?.tokens?.refresh,
          tokenLife: Math.floor(Date.now() / 1000),
        });
        window.location.replace("/dashboard");
      }
    },
    onError: (error) => {
      if (error.response) {
        SnackbarUtils.error(error.response.data.message);
      } else {
        SnackbarUtils.error(error.message);
      }
    },
  });

  const mutation = useMutation(registration, {
    onSuccess: (response) => {
      if (response.data.status) {
        console.log(response);
      }
      loginmutation.mutate({
        email: email,
        password: password,
      });
    },
  });
  const { isSuccess, isLoading, isError, error: serverError, data: serverResponse } = mutation;

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (email === "") {
      isValid = false;
      errors["email"] = "Please enter email address.";
    }
    if (email !== "") {
      let pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        isValid = false;
        errors["email"] = "Please enter a valid email address.";
      }
    }
    if (password === "") {
      isValid = false;
      errors["password"] = "Please enter password.";
    }
    setError(errors);
    return isValid;
  };

  const onClickRegister = () => {
    if (validate()) {
      const postData = {
        email: email,
        password: password,
        confirm_password: password,
        invited_by: referralCode,
      };
      mutation.mutate(postData);
    }
  };

  return (
    <IllustrationLayout
      title="Sign Up"
      description="Create new account of USDFX and do more with Crypto trade confidently "
      illustration={{
        image: bgImage,
        title: '"Attention is the new currency"',
        description:
          "The more effortless the writing looks, the more effort the writer actually put into the process.",
      }}
    >
      <ArgonBox component="form" role="form">
        {isSuccess && serverResponse.status === "401" ? (
          <div className="getError_md_start">
            <p>{serverResponse?.response?.data?.message}</p>
          </div>
        ) : (
          isError && (
            <div className="getError_md_start">
              <p>{serverError?.response?.data?.message}</p>
            </div>
          )
        )}
        <ArgonBox mb="12px">
          <InputLabel
            style={{
              fontSize: "14px",
              lineHeight: "19px",
              fontWeight: "700",
              color: "#717579",
              marginBottom: "4px",
            }}
          >
            Email
          </InputLabel>
          <ArgonInput
            type="email"
            placeholder="Email"
            size="large"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {error.email && (
            <div className="getError_md_start">
              <p>{error.email}</p>
            </div>
          )}
        </ArgonBox>
        <ArgonBox mb={2}>
          <InputLabel
            style={{
              fontSize: "14px",
              lineHeight: "19px",
              fontWeight: "700",
              color: "#717579",
              marginBottom: "4px",
            }}
          >
            Password
          </InputLabel>
          <ArgonInput
            type="password"
            placeholder="Password"
            size="large"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error.password && (
            <div className="getError_md_start">
              <p>{error.password}</p>
            </div>
          )}
        </ArgonBox>
        <ArgonBox display="flex" alignItems="top">
          <Checkbox color="primary" checked={rememberMe} onClick={() => handleSetRememberMe()} />
          <ArgonTypography
            variant="button"
            fontWeight="regular"
            sx={{ cursor: "pointer", userSelect: "none" }}
            onClick={() => handleSetRememberMe()}
          >
            I hereby confirm that I have read and agree to the Terms & Conditions, Wallet Terms,
            Earn Terms, Exchange Terms, and Privacy Policy of USDFX
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox mt={3}>
          <ArgonButton
            color="primary"
            size="large"
            fullWidth
            disabled={!rememberMe}
            onClick={onClickRegister}
          >
            Register
          </ArgonButton>
        </ArgonBox>
        <ArgonBox mt={3} textAlign="center">
          <ArgonTypography variant="button" color="text" fontWeight="regular">
            Already have an account?{" "}
            <ArgonTypography
              component={Link}
              to="/login"
              variant="button"
              color="primary"
              fontWeight="medium"
            >
              Log In
            </ArgonTypography>
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
    </IllustrationLayout>
  );
}
const WIndicatorPanel = connect(
  mapStoreToProps(components.AUTH),
  mapDispatchToProps(components.AUTH)
)(Illustration);
export default WIndicatorPanel;
