import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import { SERVER_URL } from "../../../constant";
import defaultAvatar from "assets/images/shapes/pattern-right.png";
import { Chip } from "@mui/material";

export const TableCell = ({ text, ml }) => {
  return (
    <ArgonBox display="flex" flexDirection="column" ml={ml}>
      <ArgonTypography variant="caption" fontWeight="medium">
        {text ? text : ""}
      </ArgonTypography>
    </ArgonBox>
  );
};

export const TableImageCell = ({ url, ml, height, width }) => {
  return (
    <ArgonBox
      borderRadius="lg"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      ml={ml}
    >
      <ArgonBox
        component="img"
        src={url ? SERVER_URL + url : defaultAvatar}
        height={height}
        width={width}
      />
    </ArgonBox>
  );
};

export const TestNetCell = ({ flag }) => {
  return (
    <ArgonBox display="flex" flexDirection="column">
      <ArgonTypography variant="caption" fontWeight="medium">
        {Number(flag) ? (
          <Chip color="info" label="Active" />
        ) : (
          <Chip label="InActive" />
        )}
      </ArgonTypography>
    </ArgonBox>
  );
};

export function Action({ item, onEdit, onDelete }) {
  return (
    <ArgonBox display="flex" flexDirection="row">
      <ArgonButton color="primary" sx={{ mr: 1 }} onClick={(e) => onEdit(item)}>
        Edit
      </ArgonButton>
      <ArgonButton color="primary" onClick={(e) => onDelete(item)}>
        Delete
      </ArgonButton>
    </ArgonBox>
  );
}
