import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import moment from "moment";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Card } from "@mui/material";
import MUIDataTable from "mui-datatables";

import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loader from "components/Loader/Loader";
import Footer from "examples/Footer";

import { connect } from "react-redux";
import { mapStoreToProps, mapDispatchToProps, components } from "store/storeToProps";

import { getAdmins } from "service/apiCaller";

function Admins(props) {
  const [data, setData] = useState([]);

  const { isLoading: isLoadingUsers, data: admins } = useQuery("get-users", getAdmins, {
    refetchOnWindowFocus: false,
  });
  if (admins?.data?.status) {
    props.getAdmins(admins.data.data);
  }

  useEffect(() => {
    setData(props.admins);
  }, [props.admins]);

  const columns = [
    {
      name: "no",
      label: "No",
      options: {
        sort: false,
        filter: false,
        customBodyRenderLite: (index) => index + 1,
      },
    },
    {
      name: "full_name",
      label: "FULL NAME",
      options: {
        filter: false,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
      },
    },
    {
      name: "admin_name",
      label: "Username",
      options: {
        filter: false,
      },
    },
    {
      name: "role",
      label: "ROLE",
      options: {
        filter: false,
      },
    },
    {
      name: "created_at",
      label: "Join Date",
      options: {
        filter: false,
        setCellHeaderProps: () => ({ align: "center" }),
        setCellProps: () => ({ align: "center" }),
        customBodyRender: (value) => moment(value).format("DD-MM-YYYY"),
      },
    },
  ];

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            root: {
              boxShadow: "none !important",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: "8px !important",
              "& > span": {
                display: "unset !important",
              },
              "& > span > button": {
                padding: "6px 8px !important",
              },
              "&:nth-of-type(7)": {
                width: 100,
              },
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              padding: "5px 12px !important",
            },
          },
        },
        MUIDataTableFooter: {
          styleOverrides: {
            root: {
              "& > tfoot > tr > td": {
                borderBottom: 0,
                padding: "0 16px !important",
              },
            },
          },
        },
      },
    });

  return (
    <DashboardLayout>
      {isLoadingUsers && <Loader />}
      <DashboardNavbar title="Users" />

      <Card>
        <ArgonBox p={3}>
          <ArgonTypography
            sx={{
              fontSize: "24px",
              lineHeight: "32px",
              fontWeight: "bold",
              color: "#000",
            }}
          >
            Admins
          </ArgonTypography>
          <ArgonBox mt={3}>
            <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                data={data}
                columns={columns}
                options={{
                  responsive: "simple",
                  selectableRows: "none",
                  filter: false,
                  print: false,
                  download: false,
                  viewColumns: false,
                  search: false,
                }}
              />
            </ThemeProvider>
          </ArgonBox>
        </ArgonBox>
      </Card>

      <Footer />
    </DashboardLayout>
  );
}

// export default Default;
const Wrapper = connect(
  mapStoreToProps(components.ADMIN),
  mapDispatchToProps(components.ADMIN)
)(Admins);

export default Wrapper;
