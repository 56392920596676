// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

// Icons
import facebookIcon from "assets/images/icons/logos_facebook.png";
import twitterIcon from "assets/images/icons/logos_twitter.png";
import linkedinIcon from "assets/images/icons/logos_linkedin.png";
import { useAuthContext } from "context/auth";
import { CLIENT_URL } from "../../../constant";
import SnackbarUtils from "utils/SnackbarUtils";

function InviteCard() {
  const { user } = useAuthContext();
  const link = CLIENT_URL + "/register?referral_code=" + user.referral_code;

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    SnackbarUtils.success("Copied!");
  };

  return (
    <Card>
      <ArgonBox p={3}>
        <Grid container spacing={5}>
          <Grid item xs={12} lg={5}>
            <ArgonBox>
              <ArgonTypography
                variant="h3"
                style={{ fontSize: "24px", lineHeight: "36px", fontWeight: "500", color: "black" }}
                mb={2}
              >
                Invite friends and Earn
              </ArgonTypography>
              <ArgonBox textAlign="left">
                <ArgonTypography
                  style={{ fontSize: "12px", lineHeight: "18px", color: "#6E798C" }}
                  mb="28px"
                >
                  met minim mollit non deserunt ullamco est sit aliqua dolor do amet sit aliqua
                  dolor.
                </ArgonTypography>
                <ArgonTypography
                  color="primary"
                  fontWeight="bold"
                  fontSize="14px"
                  lineHeight="19px"
                >
                  Go to Refer & Earn Page
                </ArgonTypography>
              </ArgonBox>
            </ArgonBox>
          </Grid>
          <Grid item xs={12} lg={7}>
            <ArgonBox mb={2}>
              <Grid container spacing={1} mt={3}>
                <Grid item xs={9} lg={9}>
                  <ArgonInput
                    color="primary"
                    value={link}
                    style={{ padding: "12px 24px" }}
                  ></ArgonInput>
                </Grid>
                <Grid item xs={3} lg={3}>
                  <ArgonButton
                    style={{ height: "48px", width: "100%" }}
                    color="primary"
                    onClick={handleCopyLink}
                  >
                    Copy link
                  </ArgonButton>
                </Grid>
              </Grid>
            </ArgonBox>
            <ArgonBox>
              <Grid container spacing={2}>
                <Grid item xs={4} lg={4}>
                  <ArgonButton style={{ background: "#EFEFF4", width: "100%" }}>
                    <ArgonBox component="img" src={facebookIcon} mr={1} />
                    Share
                  </ArgonButton>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <ArgonButton style={{ background: "#EFEFF4", width: "100%" }}>
                    <ArgonBox component="img" src={twitterIcon} mr={1} />
                    Tweet
                  </ArgonButton>
                </Grid>
                <Grid item xs={4} lg={4}>
                  <ArgonButton style={{ background: "#EFEFF4", width: "100%" }}>
                    <ArgonBox component="img" src={linkedinIcon} mr={1} />
                    Post
                  </ArgonButton>
                </Grid>
              </Grid>
            </ArgonBox>
          </Grid>
        </Grid>
      </ArgonBox>
    </Card>
  );
}

export default InviteCard;
