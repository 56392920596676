import { useState, useEffect, useReducer } from "react";
// Argon Dashboard 2 MUI Components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import Dialog from "@mui/material/Dialog";
import { AddCircle } from "@mui/icons-material";
import List from "@mui/material/List";
import DialogTitle from "@mui/material/DialogTitle";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loader from "components/Loader/Loader";
import Footer from "examples/Footer";

import CoinTable from "examples/Tables/Table";
import { generateRows, columns } from "./CoinTableData";
import { DlgItem } from "examples/Tables/components/DlgComponents";
import { mapStoreToProps, mapDispatchToProps, components } from "store/storeToProps";
import { useAuthContext } from "context/auth";
import { connect } from "react-redux";
import { useQuery } from "react-query";
import {
  getNetworks,
  getCoinsByNetwork,
  createCoin,
  editCoin,
  deleteCoin,
} from "service/apiCaller";
import {
  createMutation,
  editMutation,
  deleteMutation,
} from "examples/Tables/components/TableMutations";
import "assets/css/style.css";
import { ImageItem } from "examples/Tables/components/DlgComponents";

function Coin(props) {
  const { admin } = useAuthContext();
  const [networkId, setNetworkId] = useState(1);
  const [openCoinDialog, setOpenCoinDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(0);
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [image_url, setImageUrl] = useState("");
  const [symbol, setSymbol] = useState("");
  const [decimal, setDecimal] = useState(18);
  const [dlgMode, setDlgMode] = useState(null);

  useEffect(() => {
    if (dlgMode === "DELETE") {
      if (confirm("Make sure to delete this Coin")) {
        onConfirmCoin();
      }
    }
  }, [dlgMode, id]);

  let rows = [];

  const onCloseCoinDialog = () => {
    setOpenCoinDialog(false);
  };

  const onRowEdit = (e) => {
    setDlgMode("EDIT");
    setId(e.id);
    setName(e.name);
    setAddress(e.address);
    setImageUrl(e.image_url);
    setSymbol(e.symbol);
    setDecimal(e.decimal);
    setOpenCoinDialog(true);
  };

  const onRowDelete = (e) => {
    setDlgMode("DELETE");
    setId(e.id);
  };

  const { isLoading: isLoadingNetwork, data: networkRes } = useQuery("network", getNetworks, {
    refetchOnWindowFocus: false,
  });
  if (networkRes && networkRes.data && networkRes.data.data && networkRes.data.data.length > 0) {
    props.getNetworks(networkRes.data.data);
  }

  const { isLoading: isLoadingCoin, data: response } = useQuery(
    ["Coin", networkId],
    getCoinsByNetwork,
    { refetchOnWindowFocus: false }
  );
  if (response && response.data && response.data.data && response.data.data.length > 0) {
    rows = response.data.data;
  }

  const createCoinMutation = createMutation(rows, createCoin, setOpenCoinDialog);
  const editCoinMutation = editMutation(rows, editCoin, setOpenCoinDialog);
  const deleteCoinMutation = deleteMutation(rows, deleteCoin, setOpenCoinDialog);

  const onConfirmCoin = () => {
    const payload = new FormData();
    payload.append("network_id", networkId);
    payload.append("address", address);
    payload.append("name", name);
    payload.append("coin", image_url);
    payload.append("symbol", symbol);
    payload.append("decimal", decimal);

    switch (dlgMode) {
      case "CREATE":
        createCoinMutation.mutate(payload);
        break;
      case "EDIT":
        payload.append("id", id);
        editCoinMutation.mutate(payload);
        break;
      case "DELETE":
        deleteCoinMutation.mutate(id);
        break;
    }
  };

  return (
    <DashboardLayout>
      {(isLoadingCoin || isLoadingNetwork || isLoading) && <Loader />}
      <Dialog
        open={openCoinDialog}
        onClose={onCloseCoinDialog}
        fullWidth={true}
        maxWidth="md"
        className="option-dlg"
      >
        <ArgonBox className="dlg-container">
          <DialogTitle>Set information of new Coin</DialogTitle>
          <List style={{ padding: "20px" }}>
            <ImageItem
              text={"Image"}
              value={image_url}
              onChange={(e) => {
                setImageUrl(e);
              }}
            />
            <DlgItem
              text={"Address"}
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
            <DlgItem
              text={"Name"}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <DlgItem
              text={"symbol"}
              value={symbol}
              onChange={(e) => {
                setSymbol(e.target.value);
              }}
            />
            <DlgItem
              text={"decimal"}
              value={decimal}
              onChange={(e) => {
                setDecimal(e.target.value);
              }}
            />
          </List>
          <ArgonBox style={{ display: "flex", justifyContent: "center" }}>
            <ArgonButton
              size="medium"
              color="primary"
              width={200}
              onClick={(e) => {
                onConfirmCoin();
              }}
            >
              Confirm
            </ArgonButton>
          </ArgonBox>
        </ArgonBox>
      </Dialog>

      <DashboardNavbar />

      <ArgonButton
        color="primary"
        sx={{ px: 2 }}
        display="flex"
        onClick={() => {
          setDlgMode("CREATE");
          setAddress("");
          setName("");
          setImageUrl("");
          setSymbol("");
          setDecimal("");
          setOpenCoinDialog(true);
        }}
      >
        <AddCircle sx={{ mr: 1 }} />
        New Coin
      </ArgonButton>
      <div className="select-network">
        <label>Select Network</label>
        <select className="form-control" onChange={(e) => setNetworkId(e.target.value)}>
          {props.networks.map((network) => (
            <option key={`network_${network.id}`} value={network.id}>
              {network.name}
            </option>
          ))}
        </select>
      </div>
      <ArgonBox mt={3} mb={3}>
        <CoinTable columns={columns} rows={generateRows(rows, onRowEdit, onRowDelete)} />
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

const Wrapper = connect(
  mapStoreToProps(components.WALLET),
  mapDispatchToProps(components.WALLET)
)(Coin);

export default Wrapper;
