import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import moment from "moment";

import {
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIDataTable from "mui-datatables";
import { PendingOutlined } from "@mui/icons-material";

import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
import ArgonTypography from "components/ArgonTypography";

import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SnackbarUtils from "utils/SnackbarUtils";

import { getWithdraws } from "service/apiCaller";
import { confirmWithdraw } from "service/apiCaller";

function Withdrawal() {
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [showDetial, setShowDetail] = useState(false);
  const [showWithdrawConfirm, setShowWithdrawConfirm] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(null);

  const mutation = useMutation(getWithdraws, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        setTotal(response?.data?.data?.count);
        setData(response?.data?.data?.rows);
      }
    },
  });

  const updateTable = (tableState) => {
    let filterOptions = { ...tableState };
    mutation.mutate(filterOptions);
  };

  const confirmMutation = useMutation(confirmWithdraw, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        handleWithdrawConfirmClose();
        handleShowDetailClose();
        updateTable();
        SnackbarUtils.success(response.data.message);
      }
    },
    onError: () => {
      handleWithdrawConfirmClose();
    },
  });

  const handleApprove = () => {
    const payload = {
      status: confirmStatus,
      amount: selectedData.amount,
      withdraw_id: selectedData.id,
      user_id: selectedData.creator_id,
    };
    confirmMutation.mutate(payload);
  };

  const handleShowDetailOpen = (dataIndex) => {
    const info = data[dataIndex];
    if (info.method == "CRYPTO") {
      setSelectedData(info);
    } else {
      setSelectedData({ ...info, fiat_address: JSON.parse(info.fiat_address) });
    }
    setShowDetail(true);
  };

  const handleShowDetailClose = () => {
    setSelectedData(null);
    setShowDetail(false);
  };

  const handleWithdrawConfirmOpen = (status) => {
    setConfirmStatus(status);
    setShowWithdrawConfirm(true);
  };

  const handleWithdrawConfirmClose = () => {
    setShowWithdrawConfirm(false);
  };

  const columns = [
    {
      name: "updatedAt",
      label: "TIME",
      options: {
        customBodyRender: (value) => {
          return moment(value).format("HH:MM, DD-MM-YYYY");
        },
      },
    },
    {
      name: "full_name",
      label: "Full Name",
    },
    {
      name: "username",
      label: "Username",
    },
    {
      name: "method",
      label: "WITHDRAW METHOD",
      options: {
        setCellHeaderProps: () => {
          return { align: "center" };
        },
        setCellProps: () => {
          return { align: "center" };
        },
      },
    },
    {
      name: "currency",
      label: "CURRENCY",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return data[dataIndex].method == "CRYPTO"
            ? data[dataIndex].coin_symbol
            : data[dataIndex].fiat_currency;
        },
      },
    },
    {
      name: "amount",
      label: "AMOUNT",
      options: {
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => {
          return { align: "right" };
        },
      },
    },
    {
      name: "status",
      label: "STATUS",
      options: {
        setCellHeaderProps: () => {
          return { align: "center" };
        },
        setCellProps: () => {
          return { align: "center" };
        },
      },
    },
    {
      name: "action",
      label: "ACTIONS",
      options: {
        sort: false,
        empty: true,
        setCellHeaderProps: () => {
          return { align: "center" };
        },
        setCellProps: () => {
          return { align: "center" };
        },
        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              <IconButton
                color="info"
                onClick={() => handleShowDetailOpen(dataIndex)}
                sx={{ padding: 0 }}
              >
                <PendingOutlined />
              </IconButton>
            </>
          );
        },
      },
    },
  ];

  const option = {
    responsive: "simple",
    selectableRows: "none",
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    pageSize: 10,
    rowsPerPageOptions: [],
    serverSide: true,
    count: total,
    onTableInit: () => {
      updateTable(null);
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "sort":
        case "changePage": {
          updateTable(tableState);
          break;
        }
        default:
          break;
      }
    },
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            root: {
              boxShadow: "none !important",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: "8px !important",
              "& > span": {
                display: "unset !important",
              },
              "& > span > button": {
                padding: "6px 8px !important",
              },
              "&:nth-of-type(7)": {
                width: 100,
              },
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              padding: "5px 12px !important",
            },
          },
        },
        MUIDataTableFooter: {
          styleOverrides: {
            root: {
              "& > tfoot > tr > td": {
                borderBottom: 0,
                padding: "0 16px !important",
              },
            },
          },
        },
      },
    });

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <ArgonBox>
        <Card sx={{ p: 3 }}>
          <ArgonTypography
            sx={{
              fontSize: "24px",
              lineHeight: "32px",
              fontWeight: "bold",
              color: "#000",
            }}
          >
            Withdraw History
          </ArgonTypography>
          <ArgonBox mt={3}>
            <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable data={data} columns={columns} options={option} />
            </ThemeProvider>
          </ArgonBox>
        </Card>
      </ArgonBox>

      <Dialog open={showWithdrawConfirm} onClose={handleWithdrawConfirmClose}>
        <DialogTitle sx={{ p: "2rem 2rem 0 2rem" }}>
          Are you sure you want to {confirmStatus == "COMPLETED" ? "approve" : "reject"}?
        </DialogTitle>
        <DialogActions>
          <ArgonButton variant="text" color="secondary" onClick={handleWithdrawConfirmClose}>
            No
          </ArgonButton>
          <ArgonButton variant="text" color="primary" onClick={handleApprove} autoFocus>
            Yes
          </ArgonButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showDetial}
        onClose={handleShowDetailClose}
        sx={{ "& .MuiPaper-root": { width: "450px" } }}
      >
        <ArgonBox p={3}>
          <ArgonTypography
            fontWeight="bold"
            lineHeight="30px"
            sx={{ borderBottom: "1px solid #d3d3d3" }}
          >
            Withdraw Information
          </ArgonTypography>
          <ArgonBox mt={1}>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={12}>
                <ArgonTypography fontSize="16px" fontWeight="medium">
                  {(selectedData && moment(selectedData.updatedAt).format("YYYY-MM-DD HH:mm:ss")) ||
                    ""}
                </ArgonTypography>
              </Grid>
              <Grid item xs={6} lg={6}>
                <ArgonBox>
                  <InputLabel sx={{ fontSize: "14px" }}>Full Name</InputLabel>
                  <ArgonTypography fontSize="16px" fontWeight="medium">
                    {(selectedData && selectedData.full_name) || ""}
                  </ArgonTypography>
                </ArgonBox>
              </Grid>
              <Grid item xs={6} lg={6}>
                <ArgonBox>
                  <InputLabel sx={{ fontSize: "14px" }}>Username</InputLabel>
                  <ArgonTypography fontSize="16px" fontWeight="medium">
                    {(selectedData && selectedData.username) || ""}
                  </ArgonTypography>
                </ArgonBox>
              </Grid>
              <Grid item xs={12} lg={6}>
                <ArgonBox>
                  <InputLabel sx={{ fontSize: "14px" }}>Amount</InputLabel>
                  <ArgonTypography fontSize="16px" fontWeight="medium">
                    {(selectedData && selectedData.amount) || ""}
                  </ArgonTypography>
                </ArgonBox>
              </Grid>
              {selectedData && selectedData.method == "CRYPTO" && (
                <>
                  <Grid item xs={12} lg={6}>
                    <ArgonBox>
                      <InputLabel sx={{ fontSize: "14px" }}>Crypto Currency</InputLabel>
                      <ArgonTypography fontSize="16px" fontWeight="medium">
                        {(selectedData && selectedData.coin_symbol) || ""}
                      </ArgonTypography>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <InputLabel sx={{ fontSize: "14px" }}>Withdraw Address</InputLabel>
                    <ArgonTypography
                      fontSize="16px"
                      fontWeight="medium"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {(selectedData && selectedData.crypto_address) || ""}
                    </ArgonTypography>
                  </Grid>
                </>
              )}
              {selectedData && selectedData.method == "FIAT" && (
                <>
                  <Grid item xs={12} lg={6}>
                    <InputLabel sx={{ fontSize: "14px" }}>Fiat Currency</InputLabel>
                    <ArgonTypography fontSize="16px" fontWeight="medium">
                      {(selectedData && selectedData.fiat_currency) || ""}
                    </ArgonTypography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputLabel sx={{ fontSize: "14px" }}>Account Name</InputLabel>
                    <ArgonTypography fontSize="16px" fontWeight="medium">
                      {(selectedData && selectedData.fiat_address.account_name) || ""}
                    </ArgonTypography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputLabel sx={{ fontSize: "14px" }}>Account Number</InputLabel>
                    <ArgonTypography fontSize="16px" fontWeight="medium">
                      {(selectedData && selectedData.fiat_address.account_number) || ""}
                    </ArgonTypography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputLabel sx={{ fontSize: "14px" }}>Bank Name</InputLabel>
                    <ArgonTypography fontSize="16px" fontWeight="medium">
                      {(selectedData && selectedData.fiat_address.bank_name) || ""}
                    </ArgonTypography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputLabel sx={{ fontSize: "14px" }}>Bank Address</InputLabel>
                    <ArgonTypography fontSize="16px" fontWeight="medium">
                      {(selectedData && selectedData.fiat_address.bank_address) || ""}
                    </ArgonTypography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputLabel sx={{ fontSize: "14px" }}>IBAN Number</InputLabel>
                    <ArgonTypography fontSize="16px" fontWeight="medium">
                      {(selectedData && selectedData.fiat_address.iban_number) || ""}
                    </ArgonTypography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputLabel sx={{ fontSize: "14px" }}>SWIFT Code</InputLabel>
                    <ArgonTypography fontSize="16px" fontWeight="medium">
                      {(selectedData && selectedData.fiat_address.swift_code) || ""}
                    </ArgonTypography>
                  </Grid>
                </>
              )}
            </Grid>
            {selectedData && selectedData.status == "PENDING" && (
              <ArgonBox mt={1} textAlign="right">
                <ArgonButton
                  color="primary"
                  sx={{ mr: 1 }}
                  onClick={() => handleWithdrawConfirmOpen("COMPLETED")}
                >
                  Approve
                </ArgonButton>
                <ArgonButton
                  color="error"
                  sx={{ mr: 1 }}
                  onClick={() => handleWithdrawConfirmOpen("CANCELED")}
                >
                  Reject
                </ArgonButton>
                <ArgonButton color="dark" onClick={handleShowDetailClose}>
                  Close
                </ArgonButton>
              </ArgonBox>
            )}
          </ArgonBox>
        </ArgonBox>
      </Dialog>

      <Footer />
    </DashboardLayout>
  );
}

export default Withdrawal;
