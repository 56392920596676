import { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import moment from "moment";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Avatar, Button, Card, Dialog, InputLabel, MenuItem, Select, Grid } from "@mui/material";
import { AttachMoney } from "@mui/icons-material";
import Icon from "@mui/material/Icon";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import MUIDataTable from "mui-datatables";

import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Loader from "components/Loader/Loader";
import Footer from "examples/Footer";

import { connect } from "react-redux";
import { mapStoreToProps, mapDispatchToProps, components } from "store/storeToProps";
import { Country, State } from "country-state-city";

import SnackbarUtils from "utils/SnackbarUtils";
import { getUsers } from "service/apiCaller";
import { getAllCoins } from "service/apiCaller";
import { confirmDeposit } from "service/apiCaller";

function Users() {
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [operator, setOperator] = useState(true)
  const [username, setUserName] = useState('');
  const [depositData, setDepositData] = useState(null);
  const [showDepositDlg, setShowDepositDlg] = useState(false);
  const [coins, setCoins] = useState([]);
  const [amount, setAmount] = useState("");
  const [asset, setAsset] = useState("BEP20");
  const [memo, setMemo] = useState("");

  const coinMutation = useMutation(getAllCoins, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        setCoins(response.data.data);
      }
    },
  });

  useEffect(() => {
    updateTable();
    coinMutation.mutate();
  }, []);

  const usersMutation = useMutation(getUsers, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        setTotal(response?.data?.data?.count);
        setData(response?.data?.data.data);
      }
    },
  });

  const updateTable = (tableState) => {
    let filterOptions = {
      username: username,
      ...tableState 
    };
    usersMutation.mutate(filterOptions);
  };

  const columns = [
    {
      name: "id",
      label: "#",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: "avatar",
      label: "AVATAR",
      options: {
        sort: false,
        filter: false,
        setCellHeaderProps: () => ({ align: "center" }),
        setCellProps: () => ({ align: "center" }),
        customBodyRender: (value) => (
          <Avatar alt="USDFX" src={value} sx={{ width: 24, height: 24 }} />
        ),
      },
    },
    {
      name: "full_name",
      label: "FULL NAME",
      options: {
        filter: false,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
      },
    },
    {
      name: "username",
      label: "Username",
      options: {
        filter: false,
      },
    },
    {
      name: "phone_number",
      label: "Phone Number",
      options: {
        filter: false,
      },
    },
    {
      name: "country",
      label: "Country",
      options: {
        customBodyRender: (value) => {
          return Country.getCountryByCode(value)?.name ? Country.getCountryByCode(value).name : "";
        },
      },
    },
    {
      name: "state",
      label: "State/Province",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const countryCode = data[dataIndex].country;
          const stateCode = data[dataIndex].state;
          return State.getStateByCodeAndCountry(stateCode, countryCode)?.name
            ? State.getStateByCodeAndCountry(stateCode, countryCode).name
            : "";
        },
      },
    },
    {
      name: "balance",
      label: "Portfolio Balance",
      options: {
        filter: false,
        setCellHeaderProps: () => ({ align: "right" }),
        setCellProps: () => ({ align: "right" }),
        customBodyRender: (value) => Number(value).toFixed(2),
      },
    },
    {
      name: "pesos_balance",
      label: "Pesos Balance",
      options: {
        filter: false,
        setCellHeaderProps: () => ({ align: "right" }),
        setCellProps: () => ({ align: "right" }),
        customBodyRender: (value) => Number(value).toFixed(2),
      },
    },
    {
      name: "affiliate_rewards",
      label: "Affiliate Rewards",
      options: {
        filter: false,
        setCellHeaderProps: () => ({ align: "right" }),
        setCellProps: () => ({ align: "right" }),
        customBodyRender: (value) => Number(value).toFixed(2),
      },
    },
    {
      name: "bep20",
      label: "BEP20",
      options: {
        filter: false,
        setCellHeaderProps: () => ({ align: "right" }),
        setCellProps: () => ({ align: "right" }),
        customBodyRender: (value) => Number(value).toFixed(2),
      },
    },
    {
      name: "trc20",
      label: "TRC20",
      options: {
        filter: false,
        setCellHeaderProps: () => ({ align: "right" }),
        setCellProps: () => ({ align: "right" }),
        customBodyRender: (value) => Number(value).toFixed(2),
      },
    },
    {
      name: "usdfx",
      label: "USDFX",
      options: {
        filter: false,
        setCellHeaderProps: () => ({ align: "right" }),
        setCellProps: () => ({ align: "right" }),
        customBodyRender: (value) => Number(value).toFixed(2),
      },
    },
    {
      name: "sponsor_name",
      label: "Invited By",
      options: {
        filter: false,
      },
    },
    {
      name: "created_at",
      label: "Join Date",
      options: {
        filter: false,
        setCellHeaderProps: () => ({ align: "center" }),
        setCellProps: () => ({ align: "center" }),
        customBodyRender: (value) => moment(value).format("DD-MM-YYYY"),
      },
    },
    {
      name: "actions",
      label: "ACTIONS",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ align: "center" }),
        setCellProps: () => ({ align: "center" }),
        customBodyRenderLite: (dataIndex) => {
          return (
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={() => handleShowDepositOpen(dataIndex)}
            >
              <AttachMoney /> Deposit
            </Button>
          );
        },
      },
    },
  ];

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            root: {
              boxShadow: "none !important",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: "8px !important",
              "& > span": {
                display: "unset !important",
              },
              "& > span > button": {
                padding: "6px 8px !important",
              },
              "&:nth-of-type(7)": {
                width: 100,
              },
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              padding: "5px 12px !important",
            },
          },
        },
        MUIDataTableFooter: {
          styleOverrides: {
            root: {
              "& > tfoot > tr > td": {
                borderBottom: 0,
                padding: "0 16px !important",
              },
            },
          },
        },
      },
    });

  const handleShowDepositOpen = (dataIndex) => {
    setDepositData(data[dataIndex]);
    setShowDepositDlg(true);
  };

  const handleShowDepositClose = () => {
    setDepositData(null);
    setMemo("");
    setAmount("");
    setShowDepositDlg(false);
  };

  const depositMutation = useMutation(confirmDeposit, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        SnackbarUtils.success(response.data.message);
        updateTable();
        handleShowDepositClose();
      }
    },
  });

  const handleDeposit = () => {
    if (Number(amount) <= 0) {
      SnackbarUtils.error("Please enter the correct amount.");
      return;
    }
    const payload = {
      operator,
      amount,
      asset,
      memo,
      uuid: depositData.uuid,
    };
    depositMutation.mutate(payload);
  };

  // changed by rsh
  const option = {
    responsive: "simple",
    selectableRows: "none",
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    pageSize: 10,
    rowsPerPageOptions: [],
    serverSide: true,
    count: total,
    onTableInit: () => {
      updateTable(null);
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "sort":
        case "changePage": {
          updateTable(tableState);
          break;
        }
        default:
          break;
      }
    },
  };

  return (
    <DashboardLayout>
      <DashboardNavbar title="Users" />
      <Grid container spacing={2} sx={{ "& label": { fontSize: "14px" }, mb: 2  }} >
        <Grid item xs={12} lg={3}>
          <ArgonBox>
            <ArgonInput
              placeholder="User Name Search"
              startAdornment={
                <Icon fontSize="small" style={{ marginRight: "10px" }}>
                  search
                </Icon>
              }
              onChange={(e) => setUserName(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  updateTable()
                }
              }}
            />
          </ArgonBox>
        </Grid>
      </Grid>

      <Card>
        <ArgonBox p={3}>
          <ArgonBox d-flex>
            <ArgonTypography
              sx={{
                fontSize: "24px",
                lineHeight: "32px",
                fontWeight: "bold",
                color: "#000",
              }}
            >
              Users
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox>
            <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable data={data} columns={columns} options={option} />
            </ThemeProvider>
          </ArgonBox>
        </ArgonBox>
      </Card>

      <Dialog
        open={showDepositDlg}
        onClose={() => handleShowDepositClose(false)}
        sx={{ "& .MuiPaper-root": { width: 400 } }}
      >
        <ArgonBox p={3}>
          <ArgonTypography fontWeight="bold">Deposit</ArgonTypography>
          <ArgonBox mt={1}>
            <InputLabel sx={{ fontSize: "14px", mb: 0.5 }}>Enter amount</InputLabel>
            <ArgonInput
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Enter amount"
              startAdornment={
                <Button size="small" onClick={() => setOperator(!operator)}>{operator ? <AddIcon color="black" /> : <RemoveIcon color="black"/>}</Button>}
            />
          </ArgonBox>
          <ArgonBox mt={1}>
            <InputLabel sx={{ fontSize: "14px", mb: 0.5 }}>Crypto Currency</InputLabel>
            <Select
              value={asset}
              onChange={(e) => setAsset(e.target.value)}
              sx={{
                "& .MuiSelect-select": {
                  width: "100% !important",
                  display: "flex !important",
                },
                "& .MuiSvgIcon-root": { display: "block" },
                height: "40px !important",
                paddingLeft: "0 !important",
              }}
            >
              <MenuItem value='PORTFOLIO'>
                <ArgonBox
                  component="img"
                  mr={2}
                  sx={{ width: 20, height: 20 }}
                />
                PORTFOLIO BALANCE
              </MenuItem>
              <MenuItem value='PESOS_BALANCE'>
                <ArgonBox
                  component="img"
                  mr={2}
                  sx={{ width: 20, height: 20 }}
                />
                PESOS BALANCE
              </MenuItem>
              <MenuItem value='AFFILIATE_REWARD'>
                <ArgonBox
                  component="img"
                  mr={2}
                  sx={{ width: 20, height: 20 }}
                />
                AFFILIATE REWARDS
              </MenuItem>
              {coins.map((coin, index) => (
                <MenuItem key={index} value={coin.symbol}>
                  <ArgonBox
                    component="img"
                    src={coin?.image_url}
                    mr={2}
                    sx={{ width: 20, height: 20 }}
                  />
                  {coin.symbol}
                </MenuItem>
              ))}
            </Select>
          </ArgonBox>
          <ArgonBox mt={1}>
            <InputLabel sx={{ fontSize: "14px", mb: 0.5 }}>Memo</InputLabel>
            <ArgonInput
              multiline
              rows={3}
              type="number"
              value={memo}
              onChange={(e) => setMemo(e.target.value)}
              placeholder="Memo"
            ></ArgonInput>
          </ArgonBox>
          <ArgonBox mt={2} textAlign="right">
            <ArgonButton color="primary" sx={{ mr: 1 }} onClick={handleDeposit}>
              Deposit
            </ArgonButton>
            <ArgonButton color="dark" onClick={() => handleShowDepositClose(false)}>
              Close
            </ArgonButton>
          </ArgonBox>
        </ArgonBox>
      </Dialog>

      <Footer />
    </DashboardLayout>
  );
}

// export default Default;
const Wrapper = connect(
  mapStoreToProps(components.USER),
  mapDispatchToProps(components.USER)
)(Users);

export default Wrapper;
