// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

import StatisticsItem from "./StatisticsItem";

function StatisticsSummaryCard({ nUsers, nInvestments, nWithdrawals }) {
  return (
    <Card>
      <ArgonBox p={3} mx={3} display="flex" justifyContent="left">
        <ArgonTypography variant="body2" color="primary" fontWeight="bold">
          Statistics Summary
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox pb={0} px={2} textAlign="center" lineHeight={1.25}>
        <Grid container spacing={3} mb={2}>
          <Grid item xs={12} md={6} lg={4}>
            <StatisticsItem title="Users" color="primary" value={nUsers} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <StatisticsItem title="Investments" color="success" value={nInvestments} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <StatisticsItem title="Deposits" color="error" value={nWithdrawals} />
          </Grid>
        </Grid>
      </ArgonBox>
    </Card>
  );
}

// Setting default values for the props of StatisticsSummaryCard
StatisticsSummaryCard.defaultProps = {
  nUsers: "790",
  nInvestments: "420",
  nWithdrawals: "95",
};

// Typechecking props for the StatisticsSummaryCard
StatisticsSummaryCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  nUsers: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nInvestments: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nWithdrawals: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default StatisticsSummaryCard;
