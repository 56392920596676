/* eslint-disable no-unused-vars */

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DetailedStatisticsCard from "examples/Cards/StatisticsCards/DetailedStatisticsCard";

// Argon example components
import BalanceSummaryCard from "./components/BalanceSummaryCard";
import StatisticsSummaryCard from "./components/StatisticsSummaryCard";


import InviteCard from "examples/Cards/InviteCard";
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard";

import pricingBadgeIcon from "assets/images/icons/pricing-badge-icon.png";
import superNodeIcon from "assets/images/icons/supernode-icon.png";
import { useAuthContext } from "context/auth";

function Default() {
  const { admin } = useAuthContext();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      
      <ArgonBox>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} md={6} lg={6}>
            <BalanceSummaryCard
                investmens_amount={2000}
                deposits_amount={500}
                withdrawals_amount={200}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <StatisticsSummaryCard
                investmens_amount={2000}
                deposits_amount={500}
                withdrawals_amount={200}
            />
          </Grid>
        </Grid>
      </ArgonBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Default;
