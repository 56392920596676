import { bindActionCreators } from 'redux'
import authCreator from './actionCreators/auth'
import walletCreator from './actionCreators/wallet'
import userCreator from './actionCreators/user'
import adminCreator from './actionCreators/admin'
const components = {
    AUTH            :   "AUTH",
    WALLET          :   "WALLET",
    USER            :   "USER",
    ADMIN            :   "ADMIN"
}
function mapStoreToProps(component) {
    switch (component) {
        case components.AUTH:
            return function (state) {
                return {
                    ...state.auth,
                }
            }
        case components.WALLET:
            return function (state) {
                return {
                    ...state.wallet,
                }
            }
        case components.USER:
            return function (state) {
                return {
                    ...state.user,
                }
            }
        case components.ADMIN:
            return function (state) {
                return {
                    ...state.admin,
                }
            }
        default:
            return undefined
    }
}
function mapDispatchToProps(component) {
    switch (component) {
        case components.AUTH:
            return function (dispatch) {
                return bindActionCreators({
                    ...authCreator
                }, dispatch)
            }
        case components.WALLET:
            return function (dispatch) {
                return bindActionCreators({
                    ...walletCreator
                }, dispatch)
            }
        case components.USER:
            return function (dispatch) {
                return bindActionCreators({
                    ...userCreator
                }, dispatch)
            }
            
        case components.ADMIN:
            return function (dispatch) {
                return bindActionCreators({
                    ...adminCreator
                }, dispatch)
            }
        default:
            return undefined 
    }
}
export {
    mapStoreToProps,
    mapDispatchToProps,
    components
}
