import { useEffect, useState } from "react";
import { useMutation } from "react-query";

import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import ArgonAlert from "components/ArgonAlert";

import SnackbarUtils from "utils/SnackbarUtils";
import { getReferralLevels, saveReferralLevels } from "service/apiCaller";

function Referral() {
  const [referralLevel, setReferralLevel] = useState(0);
  const [referralPercentages, setReferralPercentages] = useState([""]);

  const getMutation = useMutation(getReferralLevels, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        setReferralLevel(response.data.data.length - 1);
        const levels = response.data.data.map((item) => item.percent);
        setReferralPercentages(levels);
      }
    },
  });

  useEffect(() => {
    getMutation.mutate();
  }, []);

  const saveMutation = useMutation(saveReferralLevels, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        SnackbarUtils.success(response.data.message);
      }
    },
  });

  const validate = () => {
    if (referralLevel == "") {
      SnackbarUtils.error("Please type referral level");
      return false;
    }

    for (let percent of referralPercentages) {
      if (percent == "" || parseInt(percent) < 0 || parseInt(percent) > 10) {
        SnackbarUtils.error("Please type the correct value");
        return false;
      }
    }

    return true;
  };

  const handleClickSave = () => {
    if (validate()) {
      const payload = {
        referral_percentages: referralPercentages,
      };

      saveMutation.mutate(payload);
    }
  };

  const handleFieldChange = (index, percent) => {
    const newPercentages = [...referralPercentages];
    newPercentages[index] = percent;
    setReferralPercentages(newPercentages);
  };

  const handleFieldCount = (event) => {
    let level = parseInt(event.target.value);
    if (level < 0) level = 0;
    if (level > 10) level = 10;
    setReferralLevel(level);
    const newFieldValues = [...referralPercentages];
    while (newFieldValues.length < level + 1) {
      newFieldValues.push("");
    }
    while (newFieldValues.length > level + 1) {
      newFieldValues.pop();
    }
    setReferralPercentages(newFieldValues);
  };

  return (
    <ArgonBox>
      <ArgonBox>
        <ArgonTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
          Referral settings
        </ArgonTypography>
        <ArgonBox display="flex" alignItems="center">
          <ArgonBox mt={0.25}>
            <ArgonInput type="number" value={referralLevel} onChange={handleFieldCount} />
          </ArgonBox>
          <ArgonBox ml={2}>
            <ArgonTypography variant="button" fontWeight="regular" color="text">
              Referral Level
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
        <ArgonBox mb={2} ml={1}>
          <ArgonTypography fontSize={12}>Enter 0 for Single Level</ArgonTypography>
        </ArgonBox>
        <ArgonAlert color="info" dismissible>
          Note: Entering &quot;3&quot; means 3% as referral percentage
        </ArgonAlert>
        {Array.from({ length: referralLevel + 1 }).map((_, index) => (
          <ArgonBox key={index} display="flex" alignItems="center" mb={0.25}>
            <ArgonBox mt={0.25}>
              <ArgonInput
                value={referralPercentages[index]}
                onChange={(event) => handleFieldChange(index, event.target.value)}
              />
            </ArgonBox>
            <ArgonBox ml={2}>
              <ArgonTypography variant="button" fontWeight="regular" color="text">
                Level {index} Percentage
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>
        ))}
      </ArgonBox>

      <ArgonBox mt={3} textAlign="right">
        <ArgonButton color="primary" onClick={handleClickSave}>
          Save
        </ArgonButton>
      </ArgonBox>
    </ArgonBox>
  );
}

export default Referral;
