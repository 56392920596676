const actionPack =  {
    auth : {
        AUTH                    :   "AUTH",
        LOGIN                   :   "LOGIN",
        REGISTER                :   "REGISTER",
        LOGOUT                  :   "LOGOUT",
    },
    context: {
        MINI_SIDENAV            :   "MINI_SIDENAV",
        DARK_SIDENAV            :   "DARK_SIDENAV",
        SIDENAV_COLOR           :   "SIDENAV_COLOR",
        TRANSPARENT_NAVBAR      :   "TRANSPARENT_NAVBAR",
        FIXED_NAVBAR            :   "FIXED_NAVBAR",
        OPEN_CONFIGURATOR       :   "OPEN_CONFIGURATOR",
        LAYOUT                  :   "LAYOUT",
        DARK_MODE               :   "DARK_MODE",
    },
    wallet : {
        GET_COINS_BY_NETWORK    :   "GET_COINS_BY_NETWORK",
        GET_NETWORK_LIST        :   "GET_NETWORK_LIST",
        GET_PAYMENT_OPTIONS     :   "GET_PAYMENT_OPTIONS"
    },
    user : {
        GET_USER_LIST           :   "GET_USER_LIST",
    },
    admin : {
        GET_ADMIN_LIST           :   "GET_ADMIN_LIST",
    },
}
export default actionPack;