
import SnackbarUtils from "utils/SnackbarUtils";
import { useMutation } from 'react-query';

const findRowById = (rows, id) => {
    for (const i in rows) {
        if (rows[i].id == id) {
            return i;
        }
    }
}

export const createMutation = (rows, createAPI, setOpenDialog) => {
    return useMutation(createAPI, {
        onSuccess: (response) => {
            if (response.data.status) {
                SnackbarUtils.success(response.data.message);
                rows.push(response.data.data);
                setOpenDialog(false);
            }
        },
    });
}

export const editMutation = (rows, editAPI, setOpenDialog) => {
    return useMutation(editAPI, {
        onSuccess: (response) => {
            if (response.data.status) {
                SnackbarUtils.success(response.data.message);
                rows[findRowById(rows, response.data.data.id)] = response.data.data;
                setOpenDialog(false);
            }
        },
    });
}

export const deleteMutation = (rows, deleteAPI, setOpenDialog) => {
    return useMutation(deleteAPI, {
        onSuccess: (response) => {
            if (response.data.status) {
                SnackbarUtils.success(response.data.message);
                rows.splice(findRowById(rows, response.data.data), 1);
                setOpenDialog(false);
            }
        },
    });
}