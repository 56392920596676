import { useEffect, useState } from "react";
import { useMutation } from "react-query";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Divider, IconButton, Modal } from "@mui/material";
import { PendingOutlined } from "@mui/icons-material";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import SnackbarUtils from "utils/SnackbarUtils";
import { getInvestments } from "service/apiCaller";
import { activateInvestment } from "service/apiCaller";
import { pauseInvestment } from "service/apiCaller";
import { deleteInvestment } from "service/apiCaller";

function MySubscription() {
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const statusFilterData = ["Paused", "Active", "Expired"];

  const handleModalOpen = (dataIndex) => {
    setSelectedData(data[dataIndex]);
    setShowModal(true);
  };
  const handleModalClose = () => {
    setSelectedData(null);
    setShowModal(false);
  };

  const activeMutation = useMutation(activateInvestment, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        SnackbarUtils.success(response?.data?.message);
        updateTable();
      }
    },
  });
  const handleActiveInvestment = () => {
    if (!selectedData) {
      SnackbarUtils.error("Something went wrong!");
    }

    const payload = {
      id: selectedData.id,
    };
    activeMutation.mutate(payload);
  };

  const pauseMutation = useMutation(pauseInvestment, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        SnackbarUtils.success(response?.data?.message);
        updateTable();
      }
    },
  });
  const handlePauseInvestment = () => {
    if (!selectedData) {
      SnackbarUtils.error("Something went wrong!");
    }

    const payload = {
      id: selectedData.id,
    };
    pauseMutation.mutate(payload);
  };

  const deleteMutation = useMutation(deleteInvestment, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        SnackbarUtils.success(response?.data?.message);
        updateTable();
      }
    },
  });
  const handleDeleteInvestment = () => {
    if (!selectedData) {
      SnackbarUtils.error("Something went wrong!");
    }

    const payload = {
      id: selectedData.id,
    };
    deleteMutation.mutate(payload);
  };

  useEffect(() => {
    updateTable(null);
  }, []);

  const mutation = useMutation(getInvestments, {
    onSuccess: (response) => {
      if (response?.data?.status) {
        setTotal(response?.data?.data?.count);
        setData(response?.data?.data?.rows);
      }
    },
  });

  const updateTable = (tableState) => {
    if (tableState) {
      const index = statusFilterData.indexOf(tableState.filterList[6][0]);
      const status = index < 0 ? null : index;
      tableState = {
        ...tableState,
        filterList: {
          type: tableState.filterList[2][0],
          status: status,
        },
      };
    }
    let filterOptions = { ...tableState };
    mutation.mutate(filterOptions);
  };

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        sort: true,
        filter: false,
        setCellHeaderProps: () => {
          return { align: "left" };
        },
        setCellProps: () => {
          return { align: "left" };
        },
      },
    },
    {
      name: "username",
      label: "Username",
      options: {
        sort: true,
        filter: false,
        setCellHeaderProps: () => {
          return { align: "left" };
        },
        setCellProps: () => {
          return { align: "left" };
        },
      },
    },
    {
      name: "type",
      label: "Package",
      options: {
        sort: true,
        filterOptions: {
          names: ["Starter", "Silver", "Gold", "Premium"],
        },
        setCellHeaderProps: () => {
          return { align: "left" };
        },
        setCellProps: () => {
          return { align: "left" };
        },
      },
    },
    {
      name: "capital",
      label: "Capital",
      options: {
        sort: true,
        filter: false,
        setCellHeaderProps: () => {
          return { align: "left" };
        },
        setCellProps: () => {
          return { align: "left" };
        },
      },
    },
    {
      name: "invest_date",
      label: "Date",
      options: {
        sort: true,
        filter: false,
        setCellHeaderProps: () => {
          return { align: "left" };
        },
        setCellProps: () => {
          return { align: "left" };
        },
      },
    },
    {
      name: "days",
      label: "Days",
      options: {
        sort: true,
        filter: false,
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => {
          return { align: "right" };
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        sort: true,
        filterOptions: {
          names: ["Active", "Paused", "Expired"],
        },
        setCellHeaderProps: () => {
          return { align: "left" };
        },
        setCellProps: () => {
          return { align: "left" };
        },
        customBodyRender: (value) => {
          if (value === 1) {
            return <Chip color="primary" label="Active" />;
          } else if (value === 0) {
            return <Chip color="warning" label="Paused" />;
          } else {
            return <Chip label="Expired" />;
          }
        },
      },
    },
    {
      name: "earning",
      label: "Earning",
      options: {
        sort: true,
        filter: false,
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => {
          return { align: "right" };
        },
        customBodyRender: (value) => {
          return value.toFixed(2);
        },
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        sort: false,
        empty: true,
        filter: false,
        setCellHeaderProps: () => {
          return { align: "center" };
        },
        setCellProps: () => {
          return { align: "center" };
        },
        customBodyRenderLite: (dataIndex) => {
          return (
            <IconButton color="primary" onClick={() => handleModalOpen(dataIndex)}>
              <PendingOutlined />
            </IconButton>
          );
        },
      },
    },
  ];

  const option = {
    responsive: "simple",
    selectableRows: "none",
    filter: true,
    print: false,
    download: false,
    viewColumns: false,
    search: true,
    pageSize: 10,
    rowsPerPageOptions: [],
    serverSide: true,
    count: total,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "sort":
        case "search":
        case "filterChange":
        case "changePage": {
          updateTable(tableState);
          break;
        }
        default:
          break;
      }
    },
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            root: {
              boxShadow: "none !important",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              paddingBottom: "8px !important",
              "& > span": {
                display: "unset !important",
              },
              "& > span > button": {
                padding: "6px 8px !important",
              },
              "&:nth-of-type(7)": {
                width: 100,
              },
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              padding: "5px 12px !important",
            },
          },
        },
        MUIDataTableFooter: {
          styleOverrides: {
            root: {
              "& > tfoot > tr > td": {
                borderBottom: 0,
                padding: "0 16px !important",
              },
            },
          },
        },
      },
    });

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Card>
        <ArgonBox p={3}>
          <ArgonTypography
            variant="h3"
            style={{
              color: "#000000",
              fontSize: "24px",
              fontWeight: "bold",
              lineHeight: "32px",
            }}
          >
            User Subscriptions
          </ArgonTypography>
          <ArgonBox>
            <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable data={data} columns={columns} options={option} />
            </ThemeProvider>
          </ArgonBox>
        </ArgonBox>
      </Card>

      <Modal open={showModal} onClose={handleModalClose}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            p: 4,
          }}
        >
          <ArgonTypography variant="h6" component="h2">
            Investment Details
          </ArgonTypography>
          <Divider />
          <ArgonBox>
            <ArgonBox>
              <Grid container spacing={1}>
                <Grid item lg={6}>
                  <ArgonTypography fontWeight="medium" fontSize={15}>
                    Package
                  </ArgonTypography>
                </Grid>
                <Grid item lg={6}>
                  <ArgonTypography fontStyle="italic" fontSize={15}>
                    {selectedData ? selectedData.type : ""}
                  </ArgonTypography>
                </Grid>
                <Grid item lg={6}>
                  <ArgonTypography fontWeight="medium" fontSize={15}>
                    Capital
                  </ArgonTypography>
                </Grid>
                <Grid item lg={6}>
                  <ArgonTypography fontStyle="italic" fontSize={15}>
                    {selectedData ? selectedData.capital : ""}
                  </ArgonTypography>
                </Grid>
                <Grid item lg={6}>
                  <ArgonTypography fontWeight="medium" fontSize={15}>
                    Date Invested
                  </ArgonTypography>
                </Grid>
                <Grid item lg={6}>
                  <ArgonTypography fontStyle="italic" fontSize={15}>
                    {selectedData ? selectedData.invest_date : ""}
                  </ArgonTypography>
                </Grid>
                <Grid item lg={6}>
                  <ArgonTypography fontWeight="medium" fontSize={15}>
                    Elapses
                  </ArgonTypography>
                </Grid>
                <Grid item lg={6}>
                  <ArgonTypography fontStyle="italic" fontSize={15}>
                    {selectedData ? selectedData.end_date : ""}
                  </ArgonTypography>
                </Grid>
                <Grid item lg={6}>
                  <ArgonTypography fontWeight="medium" fontSize={15}>
                    Days
                  </ArgonTypography>
                </Grid>
                <Grid item lg={6}>
                  <ArgonTypography fontStyle="italic" fontSize={15}>
                    {selectedData ? selectedData.days : ""}
                  </ArgonTypography>
                </Grid>
                <Grid item lg={6}>
                  <ArgonTypography fontWeight="medium" fontSize={15}>
                    Earning
                  </ArgonTypography>
                </Grid>
                <Grid item lg={6}>
                  <ArgonTypography fontStyle="italic" fontSize={15}>
                    {selectedData ? selectedData.cur_earning : ""}
                  </ArgonTypography>
                </Grid>
                <Grid item lg={6}>
                  <ArgonTypography fontWeight="medium" fontSize={15}>
                    Status
                  </ArgonTypography>
                </Grid>
                <Grid item lg={6}>
                  <ArgonTypography fontStyle="italic" fontSize={15}>
                    {selectedData ? statusFilterData[selectedData.status] : ""}
                  </ArgonTypography>
                </Grid>
              </Grid>
            </ArgonBox>
            <Divider />
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center">
              <ArgonButton size="small" color="primary" onClick={handleActiveInvestment}>
                Activate
              </ArgonButton>
              <ArgonButton size="small" color="warning" onClick={handlePauseInvestment}>
                Pause
              </ArgonButton>
              <ArgonButton size="small" color="dark" onClick={handleDeleteInvestment}>
                Delete
              </ArgonButton>
            </ArgonBox>
          </ArgonBox>
        </Card>
      </Modal>

      <Footer />
    </DashboardLayout>
  );
}

export default MySubscription;
