// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI base styles
import typography from "assets/theme/base/typography";

function Footer({ company }) {
  const { href, name } = company;
  const { size } = typography;

  return (
    <ArgonBox width="95%" position="absolute" bottom="24px">
      <ArgonBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize="13px"
        lineHeight="24px"
      >
        Copyright &copy;
        <Link href={href} target="_blank">
          <ArgonTypography variant="button" fontWeight="medium" color="primary">
            &nbsp;{name}&nbsp;{new Date().getFullYear()}.&nbsp;
          </ArgonTypography>
        </Link>
        All rights Reserved.
      </ArgonBox>
    </ArgonBox>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: { href: "https://www.creative-tim.com/", name: "USDFX for Business" },
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
};

export default Footer;
