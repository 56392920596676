import { useState } from "react";

import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

function General({ setting, save }) {
  const [gatewayFee, setGatewayFee] = useState(setting.gateway_fee);
  const [blockchainFee, setBlockchainFee] = useState(setting.blockchain_fee);

  const handleClickSave = () => {
    const payload = {
      gateway_fee: gatewayFee,
      blockchain_fee: blockchainFee,
    };

    save.mutate(payload);
  };

  return (
    <ArgonBox>
      <ArgonBox>
        <ArgonTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
          Fee Settings
        </ArgonTypography>
        <ArgonBox display="flex" alignItems="center" mb={0.25}>
          <ArgonBox mt={0.25}>
            <ArgonInput
              value={gatewayFee}
              onChange={(event) => setGatewayFee(event.target.value)}
              sx={{
                "& .MuiInputBase-input": {
                  textAlign: "right",
                },
              }}
            />
          </ArgonBox>
          <ArgonBox ml={2}>
            <ArgonTypography variant="button" fontWeight="regular" color="text">
              Gateway Fee
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
        <ArgonBox display="flex" alignItems="center" mb={0.25}>
          <ArgonBox mt={0.25}>
            <ArgonInput
              value={blockchainFee}
              onChange={(event) => setBlockchainFee(event.target.value)}
              sx={{
                "& .MuiInputBase-input": {
                  textAlign: "right",
                },
              }}
            />
          </ArgonBox>
          <ArgonBox ml={2}>
            <ArgonTypography variant="button" fontWeight="regular" color="text">
              Blockchain Fee
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>

      <ArgonBox mt={3} textAlign="right">
        <ArgonButton color="primary" onClick={handleClickSave}>
          Save
        </ArgonButton>
      </ArgonBox>
    </ArgonBox>
  );
}

export default General;
