// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.getError_md_start {
    font-size: 14px;
    color: red;
}`, "",{"version":3,"sources":["webpack://./src/layouts/authentication/auth.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,UAAU;AACd","sourcesContent":[".getError_md_start {\n    font-size: 14px;\n    color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
