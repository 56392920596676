import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Router } from "react-router-dom";

import { Provider } from 'react-redux'
import App from "App";
// Soft UI Context Provider
import { ArgonControllerProvider } from "context";
import { AuthProvider } from 'context/auth';
import { SocketProvider } from "context/socket";

import { QueryClient, QueryClientProvider } from 'react-query';
// react-perfect-scrollbar component
import PerfectScrollbar from "react-perfect-scrollbar";
import store from './store/store'
import history from 'store/history';

// react-perfect-scrollbar styles
import "react-perfect-scrollbar/dist/css/styles.css";
const container = document.getElementById("root");
const root = createRoot(container);
const queryClient = new QueryClient();
root.render(
  // <BrowserRouter>
    <Provider store={ store }>
      <BrowserRouter history={history}>
        <QueryClientProvider client={queryClient}>
            <ArgonControllerProvider>
            <PerfectScrollbar>
                <AuthProvider>
                  <SocketProvider>
                    <App/>
                  </SocketProvider>
                </AuthProvider>
            </PerfectScrollbar>
            </ArgonControllerProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </Provider>
  
);
