// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-loader {
    max-height: unset!important;
    width: 100%;
    height: 100%;
    position: relative;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background: transparent;
}
.form-loader div.sk-fade-in {
    position: relative;
    top: 50%;
    left: 50%;
    width: 100px;
    transform: translateY(-50%);
    animation: none;
    -webkit-animation: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,QAAQ;IACR,MAAM;IACN,uBAAuB;AAC3B;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,YAAY;IACZ,2BAA2B;IAC3B,eAAe;IACf,uBAAuB;AAC3B","sourcesContent":[".form-loader {\n    max-height: unset!important;\n    width: 100%;\n    height: 100%;\n    position: relative;\n    height: 100%;\n    position: absolute;\n    right: 0;\n    top: 0;\n    background: transparent;\n}\n.form-loader div.sk-fade-in {\n    position: relative;\n    top: 50%;\n    left: 50%;\n    width: 100px;\n    transform: translateY(-50%);\n    animation: none;\n    -webkit-animation: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
