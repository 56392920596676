// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

// Images
import starterIcon from "assets/images/icons/pricing/pricing-starter-icon.png";
import silverIcon from "assets/images/icons/pricing/pricing-silver-icon.png";
import goldIcon from "assets/images/icons/pricing/pricing-gold-icon.png";
import premiumIcon from "assets/images/icons/pricing/pricing-premium-icon.png";

function DefaultPricingCard({ type, percentage, days }) {
  return (
    <Card>
      <ArgonBox px="30px" pt="8px" pb="18px">
        <ArgonBox display="flex" justifyContent="space-between" alignItems="center">
          <ArgonBox display="flex" flexDirection="column" alignItems="center">
            {type == "Starter" && <ArgonBox component="img" src={starterIcon} />}
            {type == "Silver" && <ArgonBox component="img" src={silverIcon} />}
            {type == "Gold" && <ArgonBox component="img" src={goldIcon} />}
            {type == "Premium" && <ArgonBox component="img" src={premiumIcon} />}
            <ArgonBox>
              <ArgonTypography
                variant="span"
                fontSize="20px"
                fontWeight="bold"
                lineHeight="22px"
                style={{ color: color[type] }}
              >
                {percentage}%
              </ArgonTypography>
              <ArgonTypography
                variant="span"
                style={{ color: "#A5ADBB" }}
                fontSize="14px"
                lineHeight="22px"
              >
                /APY
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>
          <ArgonBox textAlign="center">
            <ArgonTypography fontSize="16px" lineHeight="22px" style={{ color: color[type] }}>
              {type}
            </ArgonTypography>
            <ArgonTypography
              fontSize="30px"
              lineHeight="34px"
              style={{ color: "#081F32", fontWeight: "700" }}
            >
              {days}
            </ArgonTypography>
            <ArgonTypography fontSize="14px" style={{ color: "#A5ADBB" }}>
              Days
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
        <ArgonBox mt="12px">
          <ArgonTypography display="flex" alignItems="center">
            <Icon style={{ color: color[type], marginRight: "10px" }}>check-bold</Icon>
            <span style={{ color: "#6E798C", fontSize: "12px", lineHeight: "22px" }}>
              Earn {percentage}% interest for {days} Days
            </span>
          </ArgonTypography>
          <ArgonTypography display="flex" alignItems="center">
            <Icon style={{ color: color[type], marginRight: "10px" }}>check-bold</Icon>
            <span style={{ color: "#6E798C", fontSize: "12px", lineHeight: "22px" }}>
              Compounded Interest
            </span>
          </ArgonTypography>
          <ArgonTypography display="flex" alignItems="center">
            <Icon style={{ color: color[type], marginRight: "10px" }}>check-bold</Icon>
            <span style={{ color: "#6E798C", fontSize: "12px", lineHeight: "22px" }}>
              Unlock your USDFX Monthly
            </span>
          </ArgonTypography>
          <ArgonTypography display="flex" alignItems="center">
            <Icon style={{ color: color[type], marginRight: "10px" }}>check-bold</Icon>
            <span style={{ color: "#6E798C", fontSize: "12px", lineHeight: "22px" }}>
              Interest are paid in USDFX
            </span>
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox textAlign="center" mt={1}>
          {type == "Starter" && (
            <ArgonButton
              size="small"
              fullWidth
              style={{
                color: "#888F96",
                background: "#A5FFAE",
                borderRadius: "8px",
                fontSize: "12px",
                fontWeight: "400",
                width: "167px",
                padding: 0
              }}
            >
              Your Current Plan
            </ArgonButton>
          )}
          {type == "Silver" && (
            <ArgonButton
            size="small"
              fullWidth
              style={{
                color: "#626ABB",
                background: "#B5CAF1",
                borderRadius: "8px",
                fontSize: "12px",
                fontWeight: "400",
                width: "167px",
                padding: 0
              }}
            >
              Upgrade to Silver
            </ArgonButton>
          )}
          {type == "Gold" && (
            <ArgonButton
            size="small"
              fullWidth
              style={{
                color: "#C79107",
                background: "#FFF4BD",
                borderRadius: "8px",
                fontSize: "12px",
                fontWeight: "400",
                width: "167px",
                padding: 0
              }}
            >
              Upgrade to Gold
            </ArgonButton>
          )}
          {type == "Premium" && (
            <ArgonButton
            size="small"
              fullWidth
              style={{
                color: "#7446EA",
                background: "#DDD1FA",
                borderRadius: "8px",
                fontSize: "12px",
                fontWeight: "400",
                width: "167px",
                padding: 0
              }}
            >
              Upgrade to Platinium
            </ArgonButton>
          )}
        </ArgonBox>
      </ArgonBox>
    </Card>
  );
}

const color = {
  Starter: "#F79264",
  Silver: "#9297D0",
  Gold: "#FFDD3A",
  Premium: "#7446EA",
};

// Typechecking props for the DefaultPricingCard
DefaultPricingCard.propTypes = {
  type: PropTypes.oneOf(["Starter", "Silver", "Gold", "Premium"]).isRequired,
  percentage: PropTypes.number.isRequired,
  days: PropTypes.number.isRequired,
};

export default DefaultPricingCard;
